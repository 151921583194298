import React from "react";
import { css, injectGlobal } from "emotion";
// import {css} from 'emotion';
import _ from "lodash";
import { Spring } from "react-spring/renderprops";
import Switch from "react-switch";

// import 'react-accessible-accordion/dist/fancy-example.css';
import "react-accessible-accordion/dist/minimal-example.css";

import Header from "../components/appComponents/Header";
import AccordionItem from "../components/appComponents/AccordionItem";
import { connect } from "react-redux";
import {
  fetchMyChoizePricing,
  fetchMyChoizeLocationAndActivityList,
} from "../actions/carsActions";
import BlackOverlay from "../components/appComponents/BlackOverlay";
import SliderModal from "../components/appComponents/SliderModal";
import PaymentRadio from "../components/appComponents/PaymentRadio";
import axios from "axios";
import { urls } from "../api/urls";
import { loginUser, updateUser, fetchProfile } from "../actions/userActions";
import { updateSelectedVendor, updateRemarks } from "../actions/orderActions";
import Moment from "moment";
import {
  updatePaymentId,
  flushOrderState,
  logTransaction,
  blockMyChoizeVehicle,
  confirmPlaceHolderOrder,
} from "../actions/orderActions";
import { navigate } from "gatsby";
import { flushCarsState } from "../actions/carsActions";
import { flushCouponState } from "../actions/couponActions";
import { flushRideState, fetchZoomcarLocations } from "../actions/rideActions";
import { flushTripState, fetchTrips } from "../actions/tripActions";
import { PulseLoader } from "react-loaders-spinners";
import Menu from "../components/appComponents/Menu";
import { sha256 } from "js-sha256";
import DropDown from "react-dropdown";
// import 'react-dropdown/style.css';
import {
  addStop,
  editStop,
  pushStopAtIndex,
  updateDestLocation,
  updatePickupLocation,
  updateCityOfStop,
  addFirstStop,
  fetchLocationByLatLong,
  updateDestCity,
} from "../actions/myChoizeActions";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Geocode from "react-geocode";
import S3FileUpload from "react-s3";
import ProfileSlider from "../components/appComponents/ProfileSlider";
import ZoomLocationSlider from "../components/appComponents/ZoomLocationSlider";

injectGlobal(`.row{
    max-width: 82rem;
    @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
        //Retina Specific
        max-width: 75rem;
    }
}`);

/*global tw*/
// /*global google*/

const styles = {
  listWrapper: css`
    ${tw`w-full bg-white overflow-y-scroll scrolling-touch`};
    border-radius: 16px 16px 0 0;
    height: 91.4511742891vh;
    // transform: translateY(-1vh);
    padding-bottom: 100px;
    box-shadow: 0 0 25px -2px rgba(0, 0, 0, 0.3);
    -webkit-overflow-scrolling: touch;
  `,
  row: css`
    ${tw`w-full flex flex-wrap justify-between items-center px-6 lg:px-0 -mx-2 lg:mx-auto`};
    max-width: 82rem;
    @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      //Retina Specific
      max-width: 75rem;
    }
  `,
  carSpecs: css`
    opacity: 0.5;
    font-family: ProximaNova-Regular;
    color: #222222;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-right: 7.5px;
    border-box: border;
    font-size: 11px;

    vertical-align: middle;
    &:after {
      display: inline-block;
      content: "|";
      margin-left: 7.5px;
    }
    &:last-child:after {
      opacity: 0;
    }
    @media (min-width: 992px) {
      font-size: 14px;
    }
  `,
  carTitle: css`
    ${tw`my-1`}
    opacity: 0.77;
    font-family: Chromatica-Bold;
    font-size: 25.89px;
    color: #222222;
    letter-spacing: -0.83px;
  `,
  carImage: css`
    height: 18vh;
    ${tw`-ml-2`}
  `,
  vendorLogo: css`
    ${tw`h-8 lg:h-16`};
    @media (min-width: 992px) {
      height: 1rem;
      width: 10rem;
    }
  `,
  totalPrice: css`
    opacity: 0.9;
    font-family: Chromatica-Bold;
    @media (min-width: 992px) {
      font-size: 36.65px;
    }
    font-size: 18px;
    color: #222222;
    letter-spacing: -1.18px;
  `,
  title: css`
    ${tw`w-full flex items-center font-medium justify-between`};
    opacity: 0.65;
    font-size: 18.65px;
    color: #222222;
    letter-spacing: -0.4px;
  `,
  itineraryLine: css`
    ${tw`w-full flex flex-wrap justify-between py-3 lg:py-4 px-6`} // border-bottom: 1px solid #F0F2F5;
  `,
  choiceFilterButton: css`
    ${tw`sticky flex items-center text-center px-6 py-2 z-100 pin-b pin-x mx-auto mb-6 md:mb-4 lg:mb-8 justify-center`};
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.2);
    border-radius: 100px;
    letter-spacing: 0.8px;
    transition: all 0.25s ease-in-out;
    background-image: linear-gradient(115deg, #aa0bca 10%, #7d0ede 39%);
    width: 95vw;
    height: 3rem;
    @media (min-width: 992px) {
      width: 33vw;
      height: 4rem;
    }
    &:hover {
      cursor: pointer;
      transform: scale(0.98);
    }
    p {
      ${tw`font-medium text-white text-center`}
      @media(min-width: 992px) {
        font-size: 1.2rem;
      }
    }
  `,
};

// function post(path, params, method, ride) {
//
//     method = method || "post"; // Set method to post by default if not specified.
//     var form = document.createElement("form");
//     form.setAttribute("method", method);
//     form.setAttribute("action", path);
//     form.setAttribute('target', 'blank');
//     let data = params.data;
//     // let ride = props.ride;
//     var hiddenField = document.createElement("input");

//     hiddenField.setAttribute("type", "hidden");
//     hiddenField.setAttribute("name", 'pickup_date');
//     hiddenField.setAttribute("value", ride.startDate + " " + ride.startTime);

//     form.appendChild(hiddenField);
//     var hiddenField = document.createElement("input");
//     hiddenField.setAttribute("type", "hidden");
//     hiddenField.setAttribute("name", 'dropoff_date');
//     hiddenField.setAttribute("value", ride.endDate + " " + ride.endTime);

//     form.appendChild(hiddenField);

//     var hiddenField = document.createElement("input");
//     hiddenField.setAttribute("type", "hidden");
//     hiddenField.setAttribute("name", 'data');
//     hiddenField.setAttribute("value", JSON.stringify(data));

//     form.appendChild(hiddenField);

//     document.body.appendChild(form);
//     form.submit();
// }

class reviewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendors: {},
      selectedVendor: {},
      showOverlay: false,
      showBookingForm: false,
      name: "",
      licenseNo: "",
      identity: "",
      phone: "",
      email: "",
      otp: "",
      isUserLoggedIn: false,
      countryCode: "+91",
      step: 1,
      payAdvance: false,
      payFull: true,
      error: "",
      remarks: "",
      isAdvancePaid: false,
      isSubmitting: false,
      menuOpen: false,
      idType: "licenseNo",
      redeemPoints: false,
      redeemedPoints: 0,
      additionalActivityList: [],
      locationList: null,
      disablePick: true,
      disableDrop: true,
      pickAddress: "",
      dropAddress: "",
      fetchedMyChoizePricing: false,
      pickLocation: null,
      dropLocation: null,
      activityKeyList: [],
      myDropAddressMsg: "",
      myPickAddressMsg: "",
      licenseFront: "",
      licenseBack: "",
      panCard: "",
      aadharFront: "",
      aadharBack: "",
      passportDetailsPage: "",
      aadharNo: "",
      panNumber: "",
      passportNo: "",
      uploadingLF: false,
      uploadingLB: false,
      uploadingPan: false,
      uploadingAF: false,
      uploadingAB: false,
      uploadingPDP: false,
      fetchingMyChoizeLocAndActivity: false,
      proceedUpload: false,
      fetchingMyChoizeVehicle: false,
      uploadingUB: false,
      utilityBill: "",
      utilityNo: "",
      myChoizeBlockedVehicle: {},
      driverCharge: null,
      nightCharge: null,
      showProceed: true,
      completeReward: false,
      showLocationSlider: false,
      showZoomOverlay: false,
      showZoomSlider: false,
      fetchingZoomLocations: false,
      zoomLocations: [],
      zoomLocationId: null,
    };
    this.accordionWrapper = null;
    this.handleChange = this.handleChange.bind(this);
    this.submitBid = this.submitBid.bind(this);
    // this.handleProceed = this.handleProceed.bind(this);
    this.submitSuccess = this.submitSuccess.bind(this);
    this.submitError = this.submitError.bind(this);
    this.handleLogoClick = this.handleLogoClick.bind(this);
    this.locationAndActivityListSuccess = this.locationAndActivityListSuccess.bind(
      this
    );
    this.locationAndActivityListError = this.locationAndActivityListError.bind(
      this
    );
    this.handleMyChoizePricing = this.handleMyChoizePricing.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.blockMyChoizeVehicle = this.blockMyChoizeVehicle.bind(this);
  }

  componentWillMount() {
    // eslint-disable-next-line
    if (typeof analytics === `undefined`) {
      return;
    }
    // eslint-disable-next-line
    analytics.page("/reviewPage", { category: "navigation", label: "Review" });

    let self = this;
    // let ride = this.props.ride;

    if (this.props.vendors === undefined) {
      return;
    }

    if (
      this.props.vendors.category !== "SELF DRIVE" &&
      !this.props.vendors.hasOwnProperty("vendors")
    ) {
      return;
    }

    if (
      this.props.vendors.category === "SELF DRIVE" &&
      !this.props.vendors.hasOwnProperty("vendors")
    ) {
      return;
    }
    this.props.vendors.category === "SELF DRIVE"
      ? this.setState(
          {
            vendors: this.props.vendors.vendors,
            selectedVendor: this.props.selectedVendor,
          },
          function() {
            self.props.dispatch(
              updateSelectedVendor(self.state.selectedVendor)
            );
          }
        )
      : this.setState(
          {
            vendors: this.props.vendors.vendors,
            selectedVendor: this.props.selectedVendor
              ? this.props.selectedVendor
              : null,
          },
          function() {
            self.props.dispatch(
              updateSelectedVendor(self.state.selectedVendor)
            );
          }
        );

    if (this.props.selectedVendor.account_name === "MyChoize") {
      this.setState({
        showMyChoizeOverlay: true,
        myChoizeSlider: true,
        fetchingMyChoizeLocAndActivity: true,
      });
      let data = {
        accountId: this.props.selectedVendor.account_id,
        data: {
          CityKey: this.props.selectedVendor.data.CityKey,
          DropoffDateTime: this.props.selectedVendor.data.DropDate,
          PickupDateTime: this.props.selectedVendor.data.PickDate,
        },
      };
      this.props.dispatch(
        fetchMyChoizeLocationAndActivityList(
          data,
          this.locationAndActivityListSuccess,
          this.locationAndActivityListError
        )
      );
    }

    if (this.props.selectedVendor.account_name === "Driven") {
      if (
        this.props.ride.pickup.city === "Hyderabad" ||
        this.props.ride.pickup.city === "Secunderabad" ||
        this.props.ride.pickup.city === "Bangalore" ||
        this.props.ride.pickup.city === "Bengaluru Rural" ||
        this.props.ride.pickup.city === "Bengaluru Urban" ||
        this.props.ride.pickup.city === "Bangalore Rural" ||
        this.props.ride.pickup.city === "Bangalore Urban" ||
        this.props.ride.pickup.city === "Bengaluru" ||
        this.props.ride.pickup.city === "Chennai" ||
        this.props.ride.pickup.city === "Vizag" ||
        this.props.ride.pickup.city === "Visakhapatnam"
      ) {
        this.setState({ showLocationSlider: true });
      }
    }

    if (this.props.selectedVendor.account_name === "Zoomcar") {
      this.setState({
        showZoomOverlay: true,
        showZoomSlider: true,
        fetchingZoomLocations: true,
      });
      let data = {
        accountId: this.props.selectedVendor.account_id,
        carGroupId: this.props.selectedVendor.model_id,
        fromDate: this.props.ride.startDate + " " + this.props.ride.startTime,
        toDate: this.props.ride.endDate + " " + this.props.ride.endTime,
        city: this.props.ride.pickup.city,
      };
      this.props.dispatch(
        fetchZoomcarLocations(
          data,
          this.zoomLocationSuccess,
          this.zoomLocationError
        )
      );
    }

    //myChoize logic begins
    // if (this.props.vendors.category === 'SELF DRIVE') {

    //     let myChoizeVendor = _.filter(this.props.vendors.vendors, (vendor, index) => {
    //         if (vendor.account_name === 'MyChoize')
    //             return Object.assign(vendor, { vendorIndex: index })
    //     });

    //     // console.log(myChoizeVendor);

    //     if (myChoizeVendor.length > 0) {
    //         myChoizeVendor.map((vendor) => {
    //             this.props.dispatch(fetchMyChoizePricing(vendor.account_id, vendor.data, vendor.vendorIndex, this.myChoizePricingSuccess, this.myChoizePricingError));
    //         });
    //     }
    // }

    if (this.props.isUserLoggedIn) {
      this.setState({
        isUserLoggedIn: true,
        name: this.props.userName,
        phone: this.props.userPhone,
        email: this.props.userEmail,
        licenseNo: this.props.user.licenseNo,
        passportNo: this.props.user.hasOwnProperty("passportNo")
          ? this.props.user.passportNo
          : "",
        aadharNo: this.props.user.hasOwnProperty("aadharNo")
          ? this.props.user.aadharNo
          : "",
        panNumber: this.props.user.hasOwnProperty("panNumber")
          ? this.props.user.panNumber
          : "",
      });
      this.props.user.hasOwnProperty("licenseFront") &&
        this.props.user.licenseFront !== "" &&
        this.setState({
          licenseFront: this.props.user.licenseFront,
          uploadingLF: false,
        });
      this.props.user.hasOwnProperty("licenseBack") &&
        this.props.user.licenseBack !== "" &&
        this.setState({
          licenseBack: this.props.user.licenseBack,
          uploadingLB: false,
        });
      this.props.user.hasOwnProperty("panCard") &&
        this.props.user.panCard !== "" &&
        this.setState({
          panCard: this.props.user.panCard,
          uploadingPan: false,
        });
      this.props.user.hasOwnProperty("aadharFront") &&
        this.props.user.aadharFront !== "" &&
        this.setState({
          aadharFront: this.props.user.aadharFront,
          uploadingAF: false,
        });
      this.props.user.hasOwnProperty("aadharBack") &&
        this.props.user.aadharBack !== "" &&
        this.setState({
          aadharBack: this.props.user.aadharBack,
          uploadingAB: false,
        });
      this.props.user.hasOwnProperty("passportDetailsPage") &&
        this.props.user.passportDetailsPage !== "" &&
        this.setState({
          passportDetailsPage: this.props.user.passportDetailsPage,
          uploadingPDP: false,
        });
      this.props.user.hasOwnProperty("utilityBill") &&
        this.props.user.utilityBill !== "" &&
        this.setState({
          utilityBill: this.props.user.utilityBill,
          uploadingUB: false,
        });
      let uploadCount = 0;
      if (
        this.state.licenseFront !== "" &&
        this.state.licenseBack !== "" &&
        this.state.licenseNo !== ""
      ) {
        uploadCount += 2;
      }
      if (this.state.panCard !== "" && this.state.panNumber !== "") {
        uploadCount += 2;
      }
      if (
        this.state.aadharFront !== "" &&
        this.state.aadharBack !== "" &&
        this.state.aadharNo !== ""
      ) {
        uploadCount += 2;
      }
      if (
        this.state.passportDetailsPage !== "" &&
        this.state.passportNo !== ""
      ) {
        uploadCount += 2;
      }
      if (this.state.utilityBill !== "" && this.state.utilityNo !== "") {
        uploadCount += 2;
      }
      if (
        uploadCount === 4 ||
        uploadCount === 6 ||
        uploadCount === 8 ||
        uploadCount === 10
      ) {
        this.setState({ proceedUpload: true });
        uploadCount = 8;
      }
    }

    this.setState({
      pickAddress:
        this.props.myChoize.pickup.hasOwnProperty("loc") &&
        this.props.myChoize.pickup.loc !== ""
          ? this.props.myChoize.pickup.loc
          : "",
      dropAddress:
        this.props.myChoize.dest.hasOwnProperty("loc") &&
        this.props.myChoize.dest.loc !== ""
          ? this.props.myChoize.dest.loc
          : "",
    });
    // console.log(this.props.vendors);
  }

  zoomLocationSuccess = (data) => {
    let zoomLocations = [];
    data.map((list) => {
      Object.assign(list, { label: list.state, value: list.id });
    });
    zoomLocations = data;
    this.setState({ zoomLocations });
  };

  zoomLocationError = (msg) => {
    alert(msg);
  };

  handleMyChoizePricing = () => {
    if (this.state.pickAddress === "" || this.state.dropAddress === "") {
      alert("Please select pickup and dropoff locations");
      return;
    } else {
      this.handlePlacePress(this.state.pickAddress, this.state.dropAddress);
      let additionalService = "";
      if (this.state.activityKeyList.length > 0) {
        let array = _.cloneDeep(this.state.activityKeyList);
        _.remove(array, function(element) {
          return element === 0;
        });
        additionalService = array.join();
        console.log(this.state.activityKeyList);
        console.log(additionalService);
      }
      let myChoizeVendor = _.filter(
        this.props.vendors.vendors,
        (vendor, index) => {
          if (vendor.account_name === "MyChoize")
            return Object.assign(vendor, { vendorIndex: index });
        }
      );

      // console.log(myChoizeVendor);
      if (myChoizeVendor.length > 0) {
        myChoizeVendor.map((vendor) => {
          this.props.dispatch(
            fetchMyChoizePricing(
              vendor.account_id,
              vendor.data,
              this.state.pickLocation,
              this.state.dropLocation,
              additionalService,
              vendor.vendorIndex,
              this.myChoizePricingSuccess,
              this.myChoizePricingError
            )
          );
        });
      }
    }
  };

  //checking mychoize pricing and adding it to the array
  myChoizePricingSuccess = (updatedVendor, index) => {
    // eslint-disable-next-line
    analytics.track("Mychoize Pricing fetched successfully", {
      category: "vendors",
      label: "mychoize fetched",
    });
    let newObj = Object.assign({}, this.state.vendors[index], updatedVendor, {
      discountedValue: updatedVendor.ride_amt,
    });
    let vendors = this.state.vendors.slice(0);
    console.log(updatedVendor);
    console.log(vendors);
    console.log(newObj);
    if (
      this.props.selectedVendor.data.TotalExpCharge ===
      newObj.data.TotalExpCharge
    ) {
      this.props.dispatch(updateSelectedVendor(newObj));
    }
    vendors[index] = newObj;
    this.setState({
      vendors,
      selectedVendor:
        this.props.selectedVendor.data.TotalExpCharge ===
        newObj.data.TotalExpCharge
          ? newObj
          : this.props.selectedVendor,
    });
    this.setState({
      myChoizeSlider: false,
      showMyChoizeOverlay: false,
      fetchedMyChoizePricing: true,
    });
  };

  //removing mychoize element from array since there was an error retrieving it
  myChoizePricingError = (index) => {
    // eslint-disable-next-line
    analytics.track("Mychoize Pricing failed", {
      category: "vendors",
      label: "mychoize fetch failed",
    });
    console.log("mychoize error");
    let vendors = this.state.vendors.slice(0);
    vendors.splice(index, 1);
    this.setState({ vendors, selectedVendor: vendors[0] });
    console.log(vendors);
    if (index === 0) {
      alert(
        "Oops looks like MyChoize is experiencing some issues. Check out the other vendors!"
      );
    }
    this.setState({
      myChoizeSlider: false,
      showMyChoizeOverlay: false,
      fetchedMyChoizePricing: false,
    });
  };

  locationAndActivityListSuccess = (data) => {
    this.setState({ fetchingMyChoizeLocAndActivity: false });
    console.log("Actual Location and activity list", data);
    let dataList = _.cloneDeep(data);
    dataList.BranchesPickupLocationList = dataList.BranchesPickupLocationList.filter(
      (pickLocation) =>
        Moment(this.props.ride.startTime, "HH:mm:ss").isBetween(
          Moment(pickLocation.ShiftFrom, "HH:mm:ss"),
          Moment(pickLocation.ShiftTo, "HH:mm:ss")
        )
    );
    dataList.BranchesDropupLocationList = dataList.BranchesDropupLocationList.filter(
      (dropLocation) =>
        Moment(this.props.ride.endTime, "HH:mm:ss").isBetween(
          Moment(dropLocation.ShiftFrom, "HH:mm:ss"),
          Moment(dropLocation.ShiftTo, "HH:mm:ss")
        )
    );
    dataList.BranchesPickupLocationList.map((list) => {
      Object.assign(list, {
        label:
          list.HubAddress +
          " | " +
          list.ShiftFrom +
          "-" +
          list.ShiftTo +
          " | " +
          list.WeekDays +
          " | " +
          list.DeliveryCharge,
        value: list,
      });
    });
    dataList.BranchesDropupLocationList.map((list) => {
      Object.assign(list, {
        label:
          list.HubAddress +
          " | " +
          list.ShiftFrom +
          "-" +
          list.ShiftTo +
          " | " +
          list.WeekDays +
          " | " +
          list.DeliveryCharge,
        value: list,
      });
    });
    console.log("Filtered Location List", dataList);
    this.setState({
      locationList: dataList,
      additionalActivityList:
        dataList.additionalActivities.AdditionalActivityList,
    });
  };

  locationAndActivityListError = (data) => {
    this.setState({ fetchingMyChoizeLocAndActivity: false });
    console.log(data);
  };

  handleVendorPress(vendor) {
    let self = this;
    if (this.state.selectedVendor.account_name !== vendor.account_name) {
      // eslint-disable-next-line
      analytics.track("Vendor Changed", {
        category: "vendor changed",
        label: vendor.account_name,
      });
    } else {
      // eslint-disable-next-line
      analytics.track("Vendor Changed", {
        category: "vendor changed",
        label: "Kms Changed Within " + vendor.account_name,
      });
    }
    this.setState({ selectedVendor: vendor }, function() {
      self.props.dispatch(updateSelectedVendor(vendor));
    });
    if (
      vendor.account_name === "MyChoize" &&
      !this.state.fetchedMyChoizePricing
    ) {
      this.setState({
        myChoizeSlider: true,
        showMyChoizeOverlay: true,
        fetchingMyChoizeLocAndActivity: true,
      });
      let data = {
        accountId: vendor.account_id,
        data: {
          CityKey: vendor.data.CityKey,
          DropoffDateTime: vendor.data.DropDate,
          PickupDateTime: vendor.data.PickDate,
        },
      };
      this.props.dispatch(
        fetchMyChoizeLocationAndActivityList(
          data,
          this.locationAndActivityListSuccess,
          this.locationAndActivityListError
        )
      );
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    let uploadCount = 0;
    if (
      this.state.licenseFront !== "" &&
      this.state.licenseBack !== "" &&
      this.state.licenseNo !== ""
    ) {
      uploadCount += 2;
    }
    if (this.state.panCard !== "" && this.state.panNumber !== "") {
      uploadCount += 2;
    }
    if (
      this.state.aadharFront !== "" &&
      this.state.aadharBack !== "" &&
      this.state.aadharNo !== ""
    ) {
      uploadCount += 2;
    }
    if (this.state.passportDetailsPage !== "" && this.state.passportNo !== "") {
      uploadCount += 2;
    }
    if (this.state.utilityBill !== "" && this.state.utilityNo !== "") {
      uploadCount += 2;
    }
    if (
      uploadCount === 4 ||
      uploadCount === 6 ||
      uploadCount === 8 ||
      uploadCount === 10
    ) {
      this.setState({ proceedUpload: true });
      uploadCount = 8;
    } else {
      this.setState({ proceedUpload: false });
    }
  }

  handleSubmit(event) {
    alert("A name was submitted: " + this.state.value);
    event.preventDefault();
  }

  handlePrev = () => {
    if (this.state.step > 1) {
      if (this.props.vendors.category === "SELF DRIVE") {
        if (this.state.step === 7) {
          this.setState({ idType: "aadharNo" });
        } else if (this.state.step === 6) {
          this.setState({ idType: "licenseNo" });
        }
        // if (this.state.step === 7 && this.state.prev === 5) {
        //     this.setState({ step: 5, error: '' });
        // }
        // else {
        //     this.setState({ step: this.state.step - 1, error: '' });
        // }
        this.setState({ step: this.state.step - 1, error: "" });
      } else if (this.props.vendors.category !== "SELF DRIVE") {
        if (this.state.step === 7) {
          this.setState({ step: this.state.step - 3, error: "" });
        } else {
          this.setState({ step: this.state.step - 1, error: "" });
        }
      }
    } else {
      return;
    }
  };
  handleNext = () => {
    this.setState({ error: "" });
    if (this.state.step === 1) {
      if (this.state.name.length < 2) {
        this.setState({ error: "Please Enter a Valid Name" });
        return;
      }
      this.setState({ step: this.state.step + 1 });
      // eslint-disable-next-line
      analytics.track("name", {
        category: "user name",
        label: sha256(this.state.name),
      });
    } else if (this.state.step === 2) {
      const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      // if(this.state.email.length===0 || !this.state.email.includes('@')){
      //     this.setState({ error: "Please Enter a Valid Email" });
      //     return;
      // }

      if (!reg.test(this.state.email)) {
        this.setState({ error: "Please Enter a Valid Email" });
        return;
      }
      // if (this.props.vendors.category === "SELF DRIVE") {
      //     this.setState({ step: this.state.step + 1 });
      // }
      // else {
      this.setState({ step: this.state.step + 1 });
      // eslint-disable-next-line
      analytics.track("email", {
        category: "user name",
        label: sha256(this.state.email),
      });

      // }
    } else if (this.state.step === 3) {
      if (this.state.phone.length !== 10) {
        this.setState({ error: "Please Enter a Valid Phone Number" });
        return;
      }
      this.sendOtp();
      // eslint-disable-next-line
      analytics.track("phone", {
        category: "user name",
        label: sha256(this.state.phone),
      });
      this.setState({ step: this.state.step + 1 });
    } else if (this.state.step === 4) {
      this.setState({ idType: "licenseNo" });
      this.authenticateOtp();
    }
    // else if (this.state.step === 5) {
    //     if (this.state.licenseNo === "") {
    //         this.setState({ error: "Please Enter a Valid License Number" });
    //         return;
    //     }
    //     this.setState({ step: this.state.step + 1 });
    // }
    else if (this.state.step === 5) {
      // this.props.dispatch(updateUser({ licenseNo: this.state.licenseNo , passportNo:this.state.hasOwnProperty('passportNo') ? this.state.passportNo : '', aadharNo:this.state.hasOwnProperty('aadharNo') ? this.state.aadharNo : '', panNumber:this.state.hasOwnProperty('panNumber') ? this.state.panNumber : ''}));
      let uploadCount = 0;
      if (
        this.state.licenseFront !== "" &&
        this.state.licenseBack !== "" &&
        this.state.licenseNo !== ""
      ) {
        uploadCount += 2;
      }
      // if(this.state.panCard !== '' && this.state.panNumber !== ''){
      //     uploadCount +=2;
      // }
      // if(this.state.aadharFront !== '' && this.state.aadharBack !== '' && this.state.aadharNo !== ''){
      //     uploadCount +=2;
      // }
      // if(this.state.passportDetailsPage !== '' && this.state.passportNo !== ''){
      //     uploadCount +=2;
      // }
      // if(this.state.utilityBill !== '' && this.state.utilityNo !== ''){
      //     uploadCount +=2;
      // }
      if (uploadCount === 2) {
        this.setState({ proceedUpload: true });
        uploadCount = 2;
        // this.props.dispatch(updateUser({
        //     licenseNo: this.state.licenseNo ,
        //     passportNo: this.state.passportNo ,
        //     aadharNo: this.state.aadharNo ,
        //     panNumber: this.state.panNumber ,
        //     utilityNo: this.state.utilityNo,
        //     licenseFront: this.state.licenseFront,
        //     licenseBack: this.state.licenseBack,
        //     passportDetailsPage: this.state.passportDetailsPage,
        //     aadharFront: this.state.aadharFront,
        //     aadharBack: this.state.aadharBack,
        //     panCard: this.state.panCard,
        //     utilityBill: this.state.utilityBill
        //     }));

        // if(this.props.selectedVendor.account_name === 'MyChoize'){
        //     this.setState({fetchingMyChoizeVehicle: true })
        //     this.blockMyChoizeVehicle();
        // }
      }
      console.log(uploadCount);
      if (uploadCount !== 2) {
        alert("Please upload your driving license details");
        // this.setState({ error: "Please upload atleast 3 Id's" });
        return;
      } else if (this.props.user.phone !== "") {
        this.setState({ step: 6, prev: 5, idType: "aadharNo" });
      } else {
        this.setState({ step: this.state.step + 1, idType: "aadharNo" });
      }
    } else if (this.state.step === 6) {
      let uploadCount = 0;
      if (
        this.state.aadharFront !== "" &&
        this.state.aadharBack !== "" &&
        this.state.aadharNo !== ""
      ) {
        uploadCount += 2;
      }
      if (uploadCount === 2) {
        this.setState({ proceedUpload: true });
        uploadCount = 4;
        this.props.dispatch(
          updateUser({
            licenseNo: this.state.licenseNo,
            aadharNo: this.state.aadharNo,
            licenseFront: this.state.licenseFront,
            licenseBack: this.state.licenseBack,
            aadharFront: this.state.aadharFront,
            aadharBack: this.state.aadharBack,
          })
        );
        if (this.props.selectedVendor.account_name === "MyChoize") {
          this.setState({ fetchingMyChoizeVehicle: true });
          this.blockMyChoizeVehicle();
        }
      }
      if (uploadCount !== 4) {
        alert("Please upload your aadhar/pan details");
        // this.setState({ error: "Please upload atleast 3 Id's" });
        return;
      } else {
        this.setState({ step: this.state.step + 1 });
      }
    } else if (this.state.step === 7) {
      // logic to check location is not empty

      // if ((this.props.ride.rentalType === 1 && this.props.selectedVendor.account_name !== 'MyChoize') || (this.props.ride.rentalType === 2 && this.props.selectedVendor.account_name === 'MyChoize')) {
      //     if (this.state.remarks !== '') {
      //         this.props.dispatch(updateRemarks(this.state.remarks));
      //     }

      //     //submitting order without advance payment for only zyppys if done by customer care
      //     if (this.state.remarks === 'customer care') {
      //         this.submitBid();
      //     }
      //     else {
      //         this.setState({ step: this.state.step + 1 });
      //     }
      // }
      // else {
      //     if (this.props.ride.rentalType === 3) {
      //         if (this.props.myChoize.pickup.hasOwnProperty('loc') && this.props.myChoize.pickup.loc !== '' && this.props.myChoize.dest.hasOwnProperty('loc') && this.props.myChoize.dest.loc !== '') {
      //             this.setState({ step: this.state.step + 1 });
      //         }
      //         else {
      //             alert("Please enter location");
      //         }
      //     }
      //     else{
      //         if (this.props.myChoize.pickup.hasOwnProperty('loc') && this.props.myChoize.pickup.loc !== '') {
      //             this.setState({ step: this.state.step + 1 });
      //         }
      //         else {
      //             alert("Please enter location");
      //         }
      //     }
      // }
      if (this.state.remarks !== "") {
        this.props.dispatch(updateRemarks(this.state.remarks));
      }

      //submitting order without advance payment for only zyppys if done by customer care
      if (this.state.remarks === "customer care") {
        this.submitBid();
      } else {
        this.setState({ step: this.state.step + 1 });
      }
    } else if (this.state.step === 8) {
      let now = Moment().format("DD-MM-YYYY HH:mm:ss");
      let processStartDate = this.props.ride.currentDateTime;
      let addTime = Moment(processStartDate, "DD-MM-YYYY HH:mm:ss")
        .add(30, "m")
        .format("DD-MM-YYYY HH:mm:ss");
      console.log(addTime);
      if (
        Moment(now, "DD-MM-YYYY HH:mm:ss").isAfter(
          Moment(addTime, "DD-MM-YYYY HH:mm:ss")
        )
      ) {
        alert("Time out! Please try again later.");
        navigate("/");
        return;
      }
      // this.setState({step:this.state.step+1});
      if (this.state.redeemPoints) {
        if (this.state.payFull) {
          if (this.state.selectedVendor.hasOwnProperty("security_deposit")) {
            if (
              this.state.selectedVendor.ride_amt -
                this.state.selectedVendor.couponValue -
                this.props.user.profile.redeemablePoints <=
              0
            ) {
              //  this.submitBid();
              this.setState({ completeReward: true });
              this.openRazorpay();
            } else {
              this.openRazorpay();
            }
          } else if (
            this.state.selectedVendor.discountedValue -
              this.props.user.profile.redeemablePoints <=
            0
          ) {
            if (this.props.ride.rentalType !== 2) {
              this.submitBid();
            } else {
              this.openRazorpay();
            }
            this.setState({ completeReward: true });
          } else {
            this.openRazorpay();
          }
        } else if (
          this.state.selectedVendor.advance_amount -
            this.props.user.profile.redeemablePoints <=
          0
        ) {
          if (this.props.ride.rentalType !== 2) {
            this.submitBid();
          } else {
            this.openRazorpay();
          }

          this.setState({ completeReward: true });
        } else {
          this.openRazorpay();
        }
      } else {
        this.openRazorpay();
      }
    }
  };

  onKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      //   alert('Enter key pressed');
      this.handleNext();
      // write your functionality here
    }
    if (event.key === "Enter" && event.shiftKey) {
      // alert('Shift+Enter key pressed');
      this.handlePrev();
    }
  };
  sendOtp = () => {
    let self = this;
    let url = urls.sendOtpUrl;
    // this.otpInput.focus();
    let otpInput = {
      user: {
        name: this.state.name,
        emailId: this.state.email,
        mobileNo: Number(this.state.phone),
        countryCode: Number(this.state.countryCode),
        fcmId: this.props.fcmToken ? this.props.fcmToken : " ",
      },
      loginType: "CUSTOMER",
    };
    self.props.dispatch(
      updateUser({
        name: self.state.name,
        phone: self.state.phone,
        email: self.state.email,
      })
    );
    axios
      .post(url, otpInput)
      .then(function(response) {
        self.props.dispatch(logTransaction("OTP Sent", otpInput));

        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  authenticateOtp = () => {
    this.setState({ error: "" });
    let self = this;

    let url = urls.authOtpUrl;
    let authinput = {
      otp: this.state.otp,
      loginType: "CUSTOMER",
    };
    axios
      .post(url, authinput)
      .then(function(response) {
        // let self = this;
        if (response.data.statusCD === 200) {
          self.props.dispatch(logTransaction("User Authenticated", response));
          self.setState({ isUserLoggedIn: true });
          let user = response.data.responseData;
          console.log(response);
          // eslint-disable-next-line
          analytics.track("otp", {
            category: "user name",
            label: sha256(self.state.otp),
          });
          // eslint-disable-next-line
          analytics.identify("User Authenticated", {
            name: self.state.name,
            email: self.state.email,
            phone: self.state.phone,
            id: user.id,
          });
          // storeKey('user', { name: user.name, phone: user.mobileNo, email: user.emailId, id: user.id });
          // eslint-disable-next-line
          analytics.track("User Logged In");
          if (!self.props.isUserLoggedIn) {
            self.props.dispatch(
              updateUser({
                name: self.state.name,
                phone: self.state.phone,
                email: self.state.email,
              })
            );
            self.props.dispatch(fetchProfile());
            // if(this.props.vendors.category === "SELF DRIVE"){
            //     self.props.dispatch(updateLicense({licenseNo:self.state.licenseNo,idType:self.state.idType,[self.state.idType]:self.state.identity}));
            // }
            if (user.hasOwnProperty("licenseNo") && user.licenseNo !== "") {
              self.props.dispatch(
                updateUser({
                  licenseNo: user.licenseNo,
                  passportNo: user.hasOwnProperty("passportNo")
                    ? user.passportNo
                    : "",
                  aadharNo: user.hasOwnProperty("aadharNo")
                    ? user.aadharNo
                    : "",
                  panNumber: user.hasOwnProperty("panNumber")
                    ? user.panNumber
                    : "",
                })
              );
              self.setState({
                licenseNo: user.licenseNo,
                passportNo: user.hasOwnProperty("passportNo")
                  ? user.passportNo
                  : "",
                aadharNo: user.hasOwnProperty("aadharNo") ? user.aadharNo : "",
                panNumber: user.hasOwnProperty("panNumber")
                  ? user.panNumber
                  : "",
                utilityNo: user.hasOwnProperty("utilityNo")
                  ? user.utilityNo
                  : "",
              });
            }
            user.hasOwnProperty("licenseFront") &&
              self.setState({
                licenseFront: user.licenseFront,
                uploadingLF: false,
              });
            user.hasOwnProperty("licenseBack") &&
              self.setState({
                licenseBack: user.licenseBack,
                uploadingLB: false,
              });
            user.hasOwnProperty("panCard") &&
              self.setState({ panCard: user.panCard, uploadingPan: false });
            user.hasOwnProperty("aadharFront") &&
              self.setState({
                aadharFront: user.aadharFront,
                uploadingAF: false,
              });
            user.hasOwnProperty("aadharBack") &&
              self.setState({
                aadharBack: user.aadharBack,
                uploadingAB: false,
              });
            user.hasOwnProperty("passportDetailsPage") &&
              self.setState({
                passportDetailsPage: user.passportDetailsPage,
                uploadingPDP: false,
              });
            user.hasOwnProperty("utilityBill") &&
              self.setState({
                utilityBill: user.utilityBill,
                uploadingUB: false,
              });
            let uploadCount = 0;
            if (
              self.state.licenseFront !== "" &&
              self.state.licenseBack !== "" &&
              self.state.licenseNo !== ""
            ) {
              uploadCount += 2;
            }
            if (self.state.panCard !== "" && self.state.panNumber !== "") {
              uploadCount += 2;
            }
            if (
              self.state.aadharFront !== "" &&
              self.state.aadharBack !== "" &&
              self.state.aadharNo !== ""
            ) {
              uploadCount += 2;
            }
            if (
              self.state.passportDetailsPage !== "" &&
              self.state.passportNo !== ""
            ) {
              uploadCount += 2;
            }
            if (self.state.utilityBill !== "" && self.state.utilityNo !== "") {
              uploadCount += 2;
            }
            if (
              uploadCount === 4 ||
              uploadCount === 6 ||
              uploadCount === 8 ||
              uploadCount === 10
            ) {
              self.setState({ proceedUpload: true });
              uploadCount = 8;
            }
            self.props.dispatch(loginUser());
          }
          if (self.props.selectedCar.category === "SELF DRIVE") {
            //self.props.dispatch(updateLicense({ licenseNo: self.state.licenseNo, idType: self.state.idType, [self.state.idType]: self.state.identity }));
            // if (self.state.selectedVendor.account_name === 'MyChoize') {
            //     // post(vendor.submit_request_url,vendor.submit_request_body,'post',this.props);
            //     post(self.state.selectedVendor.submit_request_url, self.state.selectedVendor.submit_request_body, 'post', self.props.ride);
            //     self.setState({ showBookingForm: false, showOverlay: false });
            // }
            if (
              self.state.selectedVendor.advance_amount > 0 ||
              self.state.selectedVendor.account_name === "MyChoize"
            ) {
              // self.openRazorpay();
              self.setState({ step: self.state.step + 1 });
            } else {
              self.submitBid();
              self.setState({ showBookingForm: false, showOverlay: false });
            }
          } else {
            if (self.state.selectedVendor.advance_amount > 0) {
              // self.openRazorpay();
              self.setState({ step: self.state.step + 3 });
            } else {
              self.submitBid();
              self.setState({ showBookingForm: false, showOverlay: false });
            }
          }
          self.props.dispatch(fetchTrips());
        } else if (response.data.statusCD === 401) {
          // alert("Invalid OTP");
          self.setState({ error: "Invalid OTP" });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  // handleProceed = () => {
  //     this.setState({ showTerms: false, showTermsOverlay: false});
  // }

  handleTerms = () => {
    // eslint-disable-next-line
    analytics.track("Proceed pressed", {
      category: "clicks",
      label: "clicked on review page",
    });
    this.setState({ showTerms: false, showTermsOverlay: false });
    if (this.state.isUserLoggedIn) {
      if (this.props.selectedCar.category === "SELF DRIVE") {
        // if (this.state.selectedVendor.account_name === 'MyChoize') {
        //     post(this.state.selectedVendor.submit_request_url, this.state.selectedVendor.submit_request_body, 'post', this.props.ride);
        // }
        if (this.props.user.licenseNo === "" || this.props.user.idType === "") {
          this.setState({ showBookingForm: true, showOverlay: true, step: 5 });
        } else if (this.state.selectedVendor.advance_amount > 0) {
          this.setState({ showBookingForm: true, showOverlay: true, step: 8 });
        } else {
          this.submitBid();
        }
      } else {
        if (this.state.selectedVendor.advance_amount > 0) {
          this.setState({ showBookingForm: true, showOverlay: true, step: 7 });
        } else {
          this.submitBid();
        }
      }
    } else {
      this.setState({ showBookingForm: true, showOverlay: true, step: 1 });
    }
  };

  submitBid = (
    payFull,
    redeemPoints,
    isAdvancePaid,
    myChoizeBlockedVehicle
  ) => {
    this.setState(
      {
        payFull,
        redeemPoints,
        isAdvancePaid,
        myChoizeBlockedVehicle,
      },
      () => {
        let redeemedPoints = this.state.redeemPoints
          ? this.state.payFull
            ? this.state.selectedVendor.hasOwnProperty("security_deposit")
              ? this.state.selectedVendor.ride_amt -
                  this.state.selectedVendor.couponValue -
                  this.props.user.profile.redeemablePoints >
                0
                ? this.state.selectedVendor.ride_amt -
                  this.state.selectedVendor.couponValue -
                  this.props.user.profile.redeemablePoints
                : 0
              : this.state.selectedVendor.discountedValue -
                  this.props.user.profile.redeemablePoints >
                0
              ? this.state.selectedVendor.discountedValue -
                this.props.user.profile.redeemablePoints
              : 0
            : this.state.selectedVendor.advance_amount -
                this.props.user.profile.redeemablePoints >
              0
            ? this.state.selectedVendor.advance_amount -
              this.props.user.profile.redeemablePoints
            : 0
          : this.state.payFull
          ? this.state.selectedVendor.hasOwnProperty("security_deposit")
            ? this.state.selectedVendor.ride_amt -
              this.state.selectedVendor.couponValue
            : this.state.selectedVendor.discountedValue
          : this.state.selectedVendor.advance_amount;
        console.log(redeemedPoints);
        // let address = { pick: this.state.pickAddress, drop: this.state.dropAddress };
        let address = {
          pick: this.state.pickAddress,
          drop: this.state.dropAddress,
          locationId: this.state.zoomLocationId,
        };
        this.setState({ isSubmitting: true, step: 0 });
        if (redeemedPoints === 1) {
          this.props.dispatch(
            confirmPlaceHolderOrder(
              address,
              this.state.selectedVendor,
              this.state.myChoizeBlockedVehicle,
              {
                redeemedPoints: this.state.payFull
                  ? this.state.selectedVendor.ride_amt -
                    this.state.selectedVendor.couponValue
                  : this.state.selectedVendor.advance_amount,
              },
              this.submitSuccess,
              this.submitError
            )
          );
          // this.props.dispatch(submitBid(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle, { redeemedPoints: this.state.payFull ? (this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue) : (this.state.selectedVendor.advance_amount) }, this.submitSuccess, this.submitError));
        } else {
          this.props.dispatch(
            confirmPlaceHolderOrder(
              address,
              this.state.selectedVendor,
              this.state.myChoizeBlockedVehicle,
              {
                redeemedPoints: this.state.redeemPoints
                  ? this.props.user.profile.redeemablePoints
                  : 0,
              },
              this.submitSuccess,
              this.submitError
            )
          );
          // this.props.dispatch(submitBid(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle, { redeemedPoints: this.state.redeemPoints ? this.props.user.profile.redeemablePoints : 0 }, this.submitSuccess, this.submitError));
        }
      }
    );
  };

  openRazorpay() {
    let self = this;
    let options = {
      // "key": "rzp_test_FtZndiI5LLQGv6", //test key
      key: "rzp_live_H8iLGrgI8ctwLj", //Production key
      amount:
        Math.trunc(
          this.state.redeemPoints
            ? this.state.payFull
              ? this.state.selectedVendor.hasOwnProperty("security_deposit")
                ? this.state.selectedVendor.ride_amt -
                    this.state.selectedVendor.couponValue -
                    this.props.user.profile.redeemablePoints >
                  0
                  ? this.state.selectedVendor.ride_amt -
                    this.state.selectedVendor.couponValue -
                    this.props.user.profile.redeemablePoints
                  : 0
                : this.state.selectedVendor.discountedValue -
                    this.props.user.profile.redeemablePoints >
                  0
                ? this.state.selectedVendor.discountedValue -
                  this.props.user.profile.redeemablePoints
                : 0
              : this.state.selectedVendor.advance_amount -
                  this.props.user.profile.redeemablePoints >
                0
              ? this.state.selectedVendor.advance_amount -
                this.props.user.profile.redeemablePoints
              : 0
            : this.state.payFull
            ? this.state.selectedVendor.hasOwnProperty("security_deposit")
              ? this.state.selectedVendor.ride_amt -
                this.state.selectedVendor.couponValue
              : this.state.selectedVendor.discountedValue
            : this.state.selectedVendor.advance_amount
        ) * 100, // 2000 paise = INR 20
      name: "Zyppys",
      description: "Trip Advance",
      handler: function(response) {
        // eslint-disable-next-line
        analytics.track("Payment Successful", {
          category: "payment",
          label: response.razorpay_payment_id,
        });
        self.props.dispatch(
          logTransaction(
            "Razorpay Payment Success",
            response.razorpay_payment_id
          )
        );
        self.setState({ isAdvancePaid: true }, function() {
          self.props.dispatch(updatePaymentId(response.razorpay_payment_id));
        });
        self.submitBid();
      },
      prefill: {
        name: this.state.name,
        email: this.state.email,
        contact: this.state.phone,
      },
      notes: {
        address: "Hello World",
      },
      theme: {
        color: "#9561e2",
      },
    };
    this.props.dispatch(logTransaction("Razorpay Opened", options));
    // eslint-disable-next-line
    var rzp = new Razorpay(options);
    rzp.open();
  }

  submitSuccess(data) {
    // eslint-disable-next-line
    analytics.track("Order Submitted", {
      category: "orders",
      label: "Order Success",
      value: this.state.payFull
        ? this.state.selectedVendor.ride_amt -
          this.state.selectedVendor.couponValue
        : this.state.selectedVendor.advance_amount,
    });
    if (this.state.payFull) {
      // eslint-disable-next-line
      analytics.track("Full Amount Paid", {
        category: "payment",
        label: "Full Amount Paid",
        value:
          this.state.selectedVendor.ride_amt -
          this.state.selectedVendor.couponValue,
      });
    } else {
      // eslint-disable-next-line
      analytics.track("Advance Paid", {
        category: "payment",
        label: "Advance Paid",
        value: this.state.selectedVendor.advance_amount,
      });
    }

    this.setState({ isSubmitting: false });
    navigate(`/confirmed`, {
      state: {
        // isAdvancePaid: this.state.isAdvancePaid,
        isFullPaid: this.state.payFull,
        amountPaid: this.state.isAdvancePaid
          ? this.state.payFull
            ? this.state.selectedVendor.ride_amt
            : this.state.selectedVendor.advance_amount
          : 0,
        name: this.state.name,
        completeReward: this.state.completeReward,
      },
    });
    console.log("isAdvancePaid", this.state.isAdvancePaid);
    console.log("payFull", this.state.payFull);
    console.log(
      "SelectedVendor Ride Amount",
      this.state.selectedVendor.ride_amt
    );
    console.log(
      "Selected Vendor Advance Amount",
      this.state.selectedVendor.advance_amount
    );
  }

  submitError(err) {
    if (err.hasOwnProperty("data") && err.data.statusCD === 404) {
      alert(err.data.statusMSG);
    } else {
      alert(
        "There was an error while placing your order. Any amount deducted will be credited back to you in 2-4 business days."
      );
    }
    this.setState({ showProceed: true });
    // eslint-disable-next-line
    analytics.track("Order Failed", {
      category: "orders",
      label: "Order Success",
      value: this.state.payFull
        ? this.state.selectedVendor.ride_amt -
          this.state.selectedVendor.couponValue
        : this.state.selectedVendor.advance_amount,
    });
    this.setState({
      isSubmitting: false,
      showBookingForm: false,
      showOverlay: false,
    });
    if (this.props.selectedCar.category === "SELF DRIVE") {
      this.setState({ step: 5 });
    } else {
      this.setState({ step: 7 });
    }
  }

  componentDidMount() {
    let ride = this.props.ride;
    // if (ride.rentalType !== 2) {
    //     if (ride.pickup.loc === "") {
    //         navigate("/");
    //         return;
    //     }
    // }
    // if (ride.rentalType === 2) {
    if (!ride.pickup.hasOwnProperty("city") || ride.pickup.city === "") {
      navigate("/");
      return;
    } else if (
      (!ride.dest.hasOwnProperty("city") || ride.dest.city === "") &&
      ride.rentalType === 1
    ) {
      navigate("/");
      return;
    }
    // }
    if (this.props.vendors.category === "SELF DRIVE") {
      if (this.state.selectedVendor.measure_cd.includes("without fuel")) {
        this.setState({ isFuel: false });
      } else {
        this.setState({ isFuel: true });
      }
    }
    // For alternating colors for accordion tabs
  }

  componentDidUpdate(prevProps, previousState) {
    // if(prevProps.stops !== this.props.stops && this.props.stops.length > 1){
    //     this.getDistanceBetweenStops();
    // }
    // if (this.input !== null && this.state.showBookingForm) {
    //     this.input.focus();
    // }
  }

  handleLogoClick = () => {
    this.props.dispatch(flushCarsState());
    this.props.dispatch(flushCouponState());
    this.props.dispatch(flushOrderState());
    this.props.dispatch(flushRideState());
    this.props.dispatch(flushTripState());
    // this.props.dispatch(flushUserState());
  };

  handleSelect = (address, isPickup) => {
    console.log(address);
    geocodeByAddress(address)
      .then((results) => {
        console.log(results[0]);
        if (results[0].address_components.length > 1) {
          geocodeByAddress(results[0].address_components[1].long_name)
            .then((results1) => {
              if (isPickup && results1[0]) {
                this.updatePickupLocation(results[0], results1[0]);
                if (this.props.selectedVendor.account_name === "MyChoize") {
                  this.updateDestLocation(results[0]);
                }
                this.setState({ address: "" });
              } else if (isPickup) {
                this.updatePickupLocation(results[0]);
                if (this.props.selectedVendor.account_name === "MyChoize") {
                  this.updateDestLocation(results[0]);
                }
                this.setState({ address: "" });
              } else {
                this.updateDestLocation(results[0]);
                this.setState({ address: "" });
              }
              getLatLng(results[0]);
            })
            .then((latLng) => console.log("Success", latLng))
            .catch((error) => console.error("Error", error));
        }
        // if (this.props.isPickup) {
        //     this.updatePickupLocation(results[0]);
        //     this.setState({ address: "" });
        // }
        // else {
        //     this.updateDestLocation(results[0]);
        //     this.setState({ address: "" });
        // }
        //         getLatLng(results[0])
      })
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
    this.setState({ formEditing: false });
  };

  updatePickupLocation = (details, altLoc) => {
    let city = "";
    let flag = 0;
    // eslint-disable-next-line
    analytics.track("Pickup Changed:", {
      category: "clicks",
      label: details.formatted_address,
    });
    this.setState({ pickupAddress: details.formatted_address });
    this.props.dispatch(
      updatePickupLocation({
        name: details.formatted_address,
        locLat: details.geometry.location.lat(),
        locLng: details.geometry.location.lng(),
      })
    );
    //Finding city's name from details object
    let types = [
      "locality",
      "administrative_area_level_2",
      "administrative_area_level_1",
    ];
    if (details.address_components.length > 1) {
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < details.address_components.length; j++) {
          if (details.address_components[j].types[0] === types[i]) {
            city = details.address_components[j].long_name;
            flag = 1;
            break;
          }
        }
        if (flag === 1) {
          break;
        }
      }
    } else {
      city = details.address_components[0].long_name;
      flag = 1;
    }
    if (
      city === "Bangalore" ||
      city === "Bengaluru Rural" ||
      city === "Bengaluru Urban" ||
      city === "Bangalore Rural" ||
      city === "Bangalore Urban"
    ) {
      city = "Bengaluru";
    }
    if (city === "Secunderabad") {
      city = "Hyderabad";
    }

    this.setState({ pickupCity: city });
    if (altLoc) {
      this.props.dispatch(
        fetchLocationByLatLong(city, altLoc.address_components[0].long_name)
      );
    } else {
      let dummy = "";
      this.props.dispatch(fetchLocationByLatLong(city, dummy));
    }
    this.props.dispatch(
      addFirstStop({
        city: city,
        name: details.formatted_address,
        latitude: details.geometry.location.lat(),
        longitude: details.geometry.location.lng(),
      })
    );
    if (this.state.isRoundtrip) {
      this.props.dispatch(
        editStop(2, {
          city: city,
          name: details.formatted_address,
          latitude: details.geometry.location.lat(),
          longitude: details.geometry.location.lng(),
          cityLat: "",
          cityLng: "",
        })
      );
    }
    // this.getLatLngOfCity(city,'pickup');
  };

  updateDestLocation = (details) => {
    let destCity = "";
    let flag = 0;
    if (this.state.pickupAddress === null) {
      this.props.dispatch(
        pushStopAtIndex(1, {
          seqId: 2,
          name: details.formatted_address,
          latitude: details.geometry.location.lat(),
          longitude: details.geometry.location.lng(),
        })
      );
    } else if (this.state.destinationAddress === null) {
      this.props.dispatch(
        addStop({
          name: details.formatted_address,
          latitude: details.geometry.location.lat(),
          longitude: details.geometry.location.lng(),
        })
      );

      //adding first stop at the last if dest is entered after toggling to round trip
      if (this.props.isRoundtrip) {
        this.props.dispatch(addStop(this.props.ride.stops[0]));
      }
    } else {
      this.props.dispatch(
        editStop(1, {
          name: details.formatted_address,
          latitude: details.geometry.location.lat(),
          longitude: details.geometry.location.lng(),
        })
      );
    }
    // eslint-disable-next-line
    analytics.track("Destination Changed:", {
      category: "clicks",
      label: details.formatted_address,
    });
    this.setState({ destinationAddress: details.formatted_address });

    this.props.dispatch(
      updateDestLocation({
        name: details.formatted_address,
        locLat: details.geometry.location.lat(),
        locLng: details.geometry.location.lng(),
      })
    );

    // this.setState({ destinationAddress: details.formatted_address });

    //Finding city's name from details object
    let types = [
      "locality",
      "administrative_area_level_2",
      "administrative_area_level_1",
    ];
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < details.address_components.length; j++) {
        if (details.address_components[j].types[0] === types[i]) {
          destCity = details.address_components[j].long_name;
          flag = 1;
          break;
        }
      }
      if (flag === 1) {
        break;
      }
    }
    if (
      destCity === "Bangalore" ||
      destCity === "Bengaluru Rural" ||
      destCity === "Bengaluru Urban" ||
      destCity === "Bangalore Rural" ||
      destCity === "Bangalore Urban"
    ) {
      destCity = "Bengaluru";
    }
    if (destCity === "Secunderabad") {
      destCity = "Hyderabad";
    }
    this.setState({ destinationCity: destCity });
    this.getLatLngOfCity(destCity, "dest");
  };

  getLatLngOfCity = (city, type) => {
    let self = this;
    Geocode.fromAddress(city).then((json) => {
      let location = json.results[0].geometry.location;

      if (type === "pickup") {
        self.props.dispatch(
          updateCityOfStop(
            {
              city: city,
              cityLat: location.lat,
              cityLng: location.lng,
            },
            0
          )
        );
      } else {
        self.props.dispatch(
          updateCityOfStop(
            {
              city: city,
              cityLat: location.lat,
              cityLng: location.lng,
            },
            1
          )
        );
        self.props.dispatch(
          updateDestCity({
            city: city,
            cityLat: location.lat,
            cityLng: location.lng,
          })
        );
      }
    });
  };

  handlePlacePress = (from, to) => {
    // this.handleSelect(from, true);
    // this.handleSelect(to, false);
    this.props.dispatch(
      updateDestLocation({
        name: to,
        locLat: null,
        locLng: null,
      })
    );
    this.props.dispatch(
      updatePickupLocation({
        name: from,
        locLat: null,
        locLng: null,
      })
    );
  };

  onDrop = (file, type, uploading) => {
    console.log(file);
    this.setState({ [uploading]: true });
    if (file[0] === null || file.length > 1) {
      return;
    } else {
      Object.defineProperty(file[0], "name", {
        value: type + "." + file[0].type.split("/")[1],
        writable: false,
      });
      const config = {
        dirName: this.state.phone,
        bucketName: "swing-partner",
        region: "us-east-1",
        accessKeyId: "AKIAIBRBFN353IM4CGFQ",
        secretAccessKey: "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",
      };

      S3FileUpload.uploadFile(file[0], config)
        .then((response) => {
          console.log(response);
          this.setState({ [type]: response.location, [uploading]: false });
          let uploadCount = 0;
          if (
            this.state.licenseFront !== "" &&
            this.state.licenseBack !== "" &&
            this.state.licenseNo !== ""
          ) {
            uploadCount += 2;
          }
          if (this.state.panCard !== "" && this.state.panNumber !== "") {
            uploadCount += 2;
          }
          if (
            this.state.aadharFront !== "" &&
            this.state.aadharBack !== "" &&
            this.state.aadharNo !== ""
          ) {
            uploadCount += 2;
          }
          if (
            this.state.passportDetailsPage !== "" &&
            this.state.passportNo !== ""
          ) {
            uploadCount += 2;
          }
          if (uploadCount === 4 || uploadCount === 6 || uploadCount === 8) {
            this.setState({ proceedUpload: true });
            uploadCount = 8;
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({ [uploading]: false });
        });
    }
  };

  blockMyChoizeVehicle() {
    let redeemedPoints = this.state.redeemPoints
      ? this.state.payFull
        ? this.state.selectedVendor.hasOwnProperty("security_deposit")
          ? this.state.selectedVendor.ride_amt -
              this.state.selectedVendor.couponValue -
              this.props.user.profile.redeemablePoints >
            0
            ? this.state.selectedVendor.ride_amt -
              this.state.selectedVendor.couponValue -
              this.props.user.profile.redeemablePoints
            : 1
          : this.state.selectedVendor.discountedValue -
              this.props.user.profile.redeemablePoints >
            0
          ? this.state.selectedVendor.discountedValue -
            this.props.user.profile.redeemablePoints
          : 1
        : this.state.selectedVendor.advance_amount -
            this.props.user.profile.redeemablePoints >
          0
        ? this.state.selectedVendor.advance_amount -
          this.props.user.profile.redeemablePoints
        : 1
      : this.state.payFull
      ? this.state.selectedVendor.hasOwnProperty("security_deposit")
        ? this.state.selectedVendor.ride_amt -
          this.state.selectedVendor.couponValue
        : this.state.selectedVendor.discountedValue
      : this.state.selectedVendor.advance_amount;
    console.log(redeemedPoints);
    // let address = { pick: this.state.pickAddress, drop: this.state.dropAddress };
    let address = {
      pick: this.state.pickAddress,
      drop: this.state.dropAddress,
      locationId: this.state.zoomLocationId,
    };
    if (redeemedPoints === 1) {
      this.props.dispatch(
        blockMyChoizeVehicle(
          address,
          this.state.selectedVendor,
          {
            redeemedPoints: this.state.payFull
              ? this.state.selectedVendor.ride_amt -
                this.state.selectedVendor.couponValue
              : this.state.selectedVendor.advance_amount,
          },
          this.blockSuccess,
          this.blockError
        )
      );
    } else {
      this.props.dispatch(
        blockMyChoizeVehicle(
          address,
          this.state.selectedVendor,
          {
            redeemedPoints: this.state.redeemPoints
              ? this.props.user.profile.redeemablePoints
              : 0,
          },
          this.blockSuccess,
          this.blockError
        )
      );
    }
  }

  blockSuccess = (data) => {
    console.log(data);
    this.setState({
      myChoizeBlockedVehicle: data,
      fetchingMyChoizeVehicle: false,
    });
  };

  blockError = (data) => {
    console.log(data);
    alert("This vehicle is sold out...Please try after some time.");
    navigate(`/carsList`);
  };

  handleZoomLocation = (location) => {
    console.log("Location", location);
    if (location === null) {
      alert("Please select a location to continue booking.");
      return;
    }
    this.setState({
      showZoomOverlay: false,
      showZoomSlider: false,
      zoomLocationId: location.value,
    });
  };

  render() {
    let ride = this.props.ride;
    // if (ride.rentalType !== 2) {
    //     if (ride.pickup.loc === "" || this.state.selectedVendor === undefined || this.state.selectedVendor === {}) {
    //         return null;
    //     }
    // }
    // if (ride.rentalType === 2) {
    if (
      !ride.pickup.hasOwnProperty("city") ||
      ride.pickup.city === "" ||
      this.state.selectedVendor === undefined ||
      this.state.selectedVendor == {}
    ) {
      return null;
    } else if (
      (!ride.dest.hasOwnProperty("city") || ride.dest.city === "") &&
      ride.rentalType === 1
    ) {
      return null;
    }
    // }
    let endDate = this.props.ride.endDate;
    let startDate = this.props.ride.startDate;
    let startTime = Moment(this.props.ride.startTime, "HH:mm:ss").format(
      "hh:mm A"
    );
    let endTime = Moment(this.props.ride.endTime, "HH:mm:ss").format("hh:mm A");
    // let options = {
    //     // types: ['(regions)'],
    //     componentRestrictions: { country: 'in' },
    //     // location: new google.maps.LatLng(this.props.ride.pickup.cityLat,this.props.ride.pickup.cityLng,[false,false]),
    //     // radius: 14,
    //     // types: ['address']
    // };
    return (
      <>
        <div
          className={css`
            ${tw`w-screen max-w-screen max-h-screen overflow-hidden relative`};
            background-image: linear-gradient(115deg, #aa0bca 0%, #7d0ede 39%);
          `}
        >
          <Header
            name={this.props.userName}
            isLoggedIn={this.props.isUserLoggedIn}
            onClick={this.handleLogoClick}
            onMenuPress={() => {
              this.setState({ menuOpen: true });
            }}
          />
          <div className={styles.listWrapper}>
            {/* Major content is here */}
            <div className={styles.row}>
              <h2
                className={css`
                  ${tw`block lg:text-2xl text-xl px-2 font-medium text-grey tracking-halfpx`};
                `}
              >
                You are Booking
                {this.props.ride.rentalType === 1 && (
                  <span
                    className={css`
                      ${tw`text-black`}
                    `}
                  >
                    {" "}
                    an Out Station Car
                  </span>
                )}
                {this.props.ride.rentalType === 2 && (
                  <span
                    className={css`
                      ${tw`text-black`}
                    `}
                  >
                    {" "}
                    a Self Drive Car
                  </span>
                )}
                {this.props.ride.rentalType === 3 && (
                  <span
                    className={css`
                      ${tw`text-black`}
                    `}
                  >
                    {" "}
                    an Airport Transfer
                  </span>
                )}
                {this.props.ride.rentalType > 3 && (
                  <span
                    className={css`
                      ${tw`text-black`}
                    `}
                  >
                    {" "}
                    an Hourly Rental Car
                  </span>
                )}
              </h2>
            </div>
            <div
              className={css`
                ${tw`w-full flex flex-wrap justify-between items-end px-10 lg:px-4 -mx-2 mb-6 lg:mx-auto`};
                max-width: 82rem;
                @media (webkit-min-device-pixel-ratio: 2),
                  (min-resolution: 192dpi) {
                  //Retina Specific
                  max-width: 75rem;
                }
              `}
            >
              <div
                className={css`
                  ${tw`w-full lg:w-1/2 py-2`}
                `}
              >
                <img
                  alt="sub_cat_url"
                  className={styles.carImage}
                  src={this.props.vendors.sub_category_url}
                />
                <h3 className={styles.carTitle}>
                  {this.props.selectedVendor.account_name !== "Swing Partner"
                    ? this.props.vendors.sub_category
                    : this.props.selectedVendor.vehicle_name}
                </h3>
                <div
                  className={css`
                    ${tw`flex mx-auto`}
                  `}
                >
                  <p className={styles.carSpecs}>
                    {this.props.vendors.no_of_seats} SEATS
                  </p>
                  {this.props.vendors.category === "SELF DRIVE" && (
                    <p className={styles.carSpecs}>
                      {this.state.selectedVendor.gear_type}
                    </p>
                  )}
                  <p className={styles.carSpecs}>
                    {this.props.vendors.category === "SELF DRIVE"
                      ? this.state.selectedVendor.fuel_type
                      : ""}
                  </p>
                </div>
              </div>
              <div
                className={css`
                  ${tw`flex flex-wrap justify-between w-full lg:w-auto py-2 -mx-2`}
                `}
              >
                <div
                  className={css`
                    ${tw`w-1/2 lg:w-full lg:text-right pl-2 lg:pl-0 text-left`}
                  `}
                >
                  {/* <div 
                                    className={css`height: 5rem; width: 10rem;`}
                                    style={{
                                        backgroundImage: `url(${this.state.selectedVendor.image_url})`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                    }}>

                                </div> */}
                  {/* <div className={
                                        css`${tw`h-8 lg:h-16`};
                                        background-image: url(${this.state.selectedVendor.image_url});
                                        // background-color: grey;
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        background-position: left 50%;
                                        @media(min-width: 992px){
                                            background-position: right 50%;
                                            height: 4rem;

                                            // width: 10rem;
                                        }`}>

                                    </div> */}
                  {/* {this.state.selectedVendor.vehicle_owner_business_name && <p style={{ textTransform: "capitalize" }}>{this.state.selectedVendor.vehicle_owner_business_name !== "" ? this.state.selectedVendor.vehicle_owner_business_name : this.state.selectedVendor?.vehicle_owner_name}</p>} */}
                  {/* <img className={styles.vendorLogo} 
                                     src={this.state.selectedVendor.image_url}/> */}
                </div>
                <div
                  className={css`
                    ${tw`w-1/2 lg:w-full text-right`}
                  `}
                >
                  {this.state.selectedVendor.couponValue > 0 ? (
                    <p
                      className={css`
                        ${tw`line-through font-brand opacity-50 text-sm lg:text-normal`}
                      `}
                    >
                      &#8377;{" "}
                      {Math.round(
                        this.state.selectedVendor.ride_amt
                      ).toLocaleString()}
                    </p>
                  ) : (
                    ""
                  )}
                  <p className={styles.totalPrice}>
                    &#8377;{" "}
                    {Math.round(
                      this.state.selectedVendor.ride_amt -
                        this.state.selectedVendor.couponValue -
                        (this.state.selectedVendor.hasOwnProperty(
                          "security_deposit"
                        ) && this.state.selectedVendor.security_deposit !== null
                          ? this.state.selectedVendor.security_deposit
                          : 0)
                    ).toLocaleString()}
                  </p>
                  {this.state.selectedVendor.hasOwnProperty("package_kms") && (
                    <p
                      className={css`
                        ${tw`opacity-50 text-sm font-brand lg:text-normal uppercase`}
                      `}
                    >
                      For{" "}
                      {this.props.vendors.category === "SELF DRIVE"
                        ? typeof this.state.selectedVendor.package_kms ===
                          "string"
                          ? this.state.selectedVendor.package_kms.includes(
                              "kms"
                            ) ||
                            this.state.selectedVendor.package_kms.includes(
                              "Kms"
                            )
                            ? this.state.selectedVendor.package_kms
                            : this.state.selectedVendor.package_kms + " Kms"
                          : this.state.selectedVendor.package_kms.toLocaleString()(
                              typeof this.state.selectedVendor.package_kms ===
                                "string" &&
                                (this.state.selectedVendor.package_kms.includes(
                                  "kms"
                                ) ||
                                  this.state.selectedVendor.package_kms.includes(
                                    "Kms"
                                  ))
                                ? ""
                                : " Kms"
                            )
                        : this.state.selectedVendor.package_kms.toLocaleString() +
                          "Kms"}
                      {this.state.selectedVendor.hasOwnProperty(
                        "security_deposit"
                      ) &&
                        this.state.selectedVendor.security_deposit !== null &&
                        parseInt(this.state.selectedVendor.security_deposit) >
                          0 && (
                          <p>
                            {" "}
                            Security Deposit{" "}
                            {this.state.selectedVendor.security_deposit.toLocaleString()}
                          </p>
                        )}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div
              className={
                css`
                  ${tw`w-full rounded-md px-4`}
                ` + " row"
              }
              ref={(node) => (this.accordionWrapper = node)}
            >
              <AccordionItem title="Price Breakdown">
                {this.props.vendors.category !== "SELF DRIVE" ? (
                  <div className={styles.itineraryLine}>
                    <div className={styles.itineraryLine}>
                      {this.state.selectedVendor.actual_cost_per_km > 0 ? (
                        <p
                          className={css`
                            ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                          `}
                        >
                          Base Fare
                          {this.state.selectedVendor.base_fare ===
                          this.state.selectedVendor.package_kms *
                            this.state.selectedVendor.actual_cost_per_km ? (
                            <span
                              className={css`
                                ${tw`font-sans w-full md:w-auto font-medium text-grey-darker block opacity-60 py-2`}
                              `}
                            >
                              {" "}
                              {this.state.selectedVendor.package_kms.toLocaleString()}{" "}
                              Kms x &#8377;{" "}
                              {this.state.selectedVendor.actual_cost_per_km.toLocaleString()}
                            </span>
                          ) : (
                            <span
                              className={css`
                                ${tw`font-sans w-full md:w-auto font-medium text-grey-darker block opacity-60 py-2`}
                              `}
                            >
                              {" "}
                              {this.state.selectedVendor.package_kms.toLocaleString()}{" "}
                              Kms
                            </span>
                          )}
                        </p>
                      ) : (
                        <p
                          className={css`
                            ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                          `}
                        >
                          Package Fare
                          <span
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker block opacity-60 py-2`}
                            `}
                          >
                            {" "}
                            {this.state.selectedVendor.package_kms.toLocaleString()}{" "}
                            Kms{" "}
                          </span>
                        </p>
                      )}
                      <p
                        className={css`
                          ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                        `}
                      >
                        &#8377;{" "}
                        {Math.round(
                          this.state.selectedVendor.base_fare
                        ).toLocaleString()}
                      </p>
                    </div>

                    {this.state.selectedVendor.hasOwnProperty(
                      "driver_charges_per_day"
                    ) &&
                      this.state.selectedVendor.driver_charges_per_day !==
                        null &&
                      this.state.selectedVendor.driver_charges_per_day > 0 && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            Driver Charges
                            <span
                              className={css`
                                ${tw`font-sans w-full md:w-auto font-medium text-grey-darker block opacity-60 py-2`}
                              `}
                            >
                              &#8377;{" "}
                              {this.state.selectedVendor.driver_charges_per_day.toLocaleString()}{" "}
                              x {this.state.selectedVendor.no_of_days} Days{" "}
                            </span>
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            &#8377;{" "}
                            {Math.floor(
                              this.state.selectedVendor.driver_charges_per_day *
                                this.state.selectedVendor.no_of_days
                            ).toLocaleString()}
                          </p>
                        </div>
                      )}

                    {this.state.selectedVendor.hasOwnProperty(
                      "night_charges"
                    ) &&
                      this.state.selectedVendor.night_charges !== null &&
                      this.state.selectedVendor.night_charges > 0 && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            Night Charges{" "}
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                            `}
                          >
                            &#8377;{" "}
                            {this.state.selectedVendor.night_charges.toLocaleString()}{" "}
                          </p>
                        </div>
                      )}

                    {this.state.selectedVendor.couponValue > 0 && (
                      <div className={styles.itineraryLine}>
                        <p
                          className={css`
                            ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                          `}
                        >
                          Coupon Discount{" "}
                        </p>
                        <p
                          className={css`
                            ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                          `}
                        >
                          -&#8377;{" "}
                          {this.state.selectedVendor.couponValue.toLocaleString()}
                        </p>
                      </div>
                    )}

                    {this.state.selectedVendor.hasOwnProperty(
                      "service_tax_amt"
                    ) &&
                      this.state.selectedVendor.service_tax_amt !== null &&
                      parseInt(this.state.selectedVendor.service_tax_amt) >
                        0 && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            GST
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            &#8377;{" "}
                            {this.state.selectedVendor.service_tax_amt.toLocaleString()}
                          </p>
                        </div>
                      )}

                    {this.state.selectedVendor.hasOwnProperty("state_tax") &&
                      this.state.selectedVendor.state_tax > 0 &&
                      parseInt(this.state.selectedVendor.state_tax) > 0 && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            Border Tax
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                            `}
                          >
                            &#8377;{" "}
                            {this.state.selectedVendor.state_tax.toLocaleString()}
                          </p>
                        </div>
                      )}

                    {this.state.selectedVendor.hasOwnProperty(
                      "service_charges"
                    ) &&
                      this.state.selectedVendor.service_charges !== null &&
                      parseInt(this.state.selectedVendor.service_charges) >
                        0 && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            Service Charges
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                            `}
                          >
                            &#8377;{" "}
                            {this.state.selectedVendor.service_charges.toLocaleString()}
                          </p>
                        </div>
                      )}

                    {this.state.selectedVendor.hasOwnProperty("toll_tax") &&
                      this.state.selectedVendor.toll_tax !== null &&
                      parseInt(this.state.selectedVendor.toll_tax) > 0 && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            Tolls
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                            `}
                          >
                            &#8377;{" "}
                            {this.state.selectedVendor.toll_tax.toLocaleString()}
                          </p>
                        </div>
                      )}

                    {this.state.selectedVendor.hasOwnProperty(
                      "extra_cost_per_hr"
                    ) &&
                      this.state.selectedVendor.extra_cost_per_hr !== null &&
                      parseInt(this.state.selectedVendor.extra_cost_per_hr) >
                        0 && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            Extra Cost per Hour
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            &#8377;{" "}
                            {this.state.selectedVendor.extra_cost_per_hr.toLocaleString()}
                          </p>
                        </div>
                      )}
                    <div className={styles.itineraryLine}>
                      <p
                        className={css`
                          ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                        `}
                      >
                        Payable Amount
                      </p>
                      <p
                        className={css`
                          ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                        `}
                      >
                        &#8377;{" "}
                        {Math.round(
                          this.state.selectedVendor.ride_amt -
                            this.state.selectedVendor.couponValue
                        ).toLocaleString()}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={styles.itineraryLine}>
                    <div className={styles.itineraryLine}>
                      <p
                        className={css`
                          ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                        `}
                      >
                        Base Fare
                        {this.state.selectedVendor.hasOwnProperty(
                          "package_kms"
                        ) && (
                          <span
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker block opacity-60 py-2`}
                            `}
                          >
                            {typeof this.state.selectedVendor.package_kms ===
                            "string"
                              ? this.state.selectedVendor.package_kms
                              : this.state.selectedVendor.package_kms.toLocaleString()}{" "}
                            {typeof this.state.selectedVendor.package_kms ===
                              "string" &&
                            (this.state.selectedVendor.package_kms.includes(
                              "kms"
                            ) ||
                              this.state.selectedVendor.package_kms.includes(
                                "Kms"
                              ))
                              ? ""
                              : "Kms"}
                          </span>
                        )}
                      </p>
                      <p
                        className={css`
                          ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                        `}
                      >
                        &#8377;{" "}
                        {Math.round(
                          this.state.selectedVendor.base_fare
                        ).toLocaleString()}
                      </p>
                    </div>

                    {this.state.selectedVendor.couponValue > 0 &&
                      this.state.selectedVendor.coupon !== null && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            Coupon Discount{" "}
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                            `}
                          >
                            -&#8377;{" "}
                            {this.state.selectedVendor.couponValue.toLocaleString()}
                          </p>
                        </div>
                      )}

                    {this.state.selectedVendor.hasOwnProperty(
                      "service_tax_amt"
                    ) &&
                      this.state.selectedVendor.service_tax_amt !== null &&
                      parseInt(this.state.selectedVendor.service_tax_amt) >
                        0 && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            GST
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            &#8377;{" "}
                            {this.state.selectedVendor.service_tax_amt.toLocaleString()}
                          </p>
                        </div>
                      )}

                    {this.state.selectedVendor.hasOwnProperty(
                      "delivery_charges"
                    ) &&
                      this.state.selectedVendor.delivery_charges !== null &&
                      parseInt(this.state.selectedVendor.delivery_charges) >
                        0 && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            Delivery Charges
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                            `}
                          >
                            &#8377;{" "}
                            {this.state.selectedVendor.delivery_charges.toLocaleString()}
                          </p>
                        </div>
                      )}

                    {/* {this.state.selectedVendor.hasOwnProperty("extra_cost_per_km") && this.state.selectedVendor.extra_cost_per_km !== null && parseInt(this.state.selectedVendor.extra_cost_per_km) > 0 &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Extra Cost per Km</p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {this.state.selectedVendor.extra_cost_per_km.toLocaleString()}</p>
                                            </div>
                                        } */}

                    {this.state.selectedVendor.hasOwnProperty(
                      "pickup_charges"
                    ) &&
                      this.state.selectedVendor.pickup_charges !== null && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            Pickup Charges
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            &#8377;{" "}
                            {this.state.selectedVendor.pickup_charges.toLocaleString()}
                          </p>
                        </div>
                      )}
                    {this.state.selectedVendor.hasOwnProperty(
                      "dropoff_charges"
                    ) &&
                      this.state.selectedVendor.dropoff_charges !== null && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            Dropoff Charges
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            &#8377;{" "}
                            {this.state.selectedVendor.dropoff_charges.toLocaleString()}
                          </p>
                        </div>
                      )}
                    {this.state.selectedVendor.hasOwnProperty(
                      "service_charges"
                    ) &&
                      this.state.selectedVendor.service_charges !== null &&
                      parseInt(this.state.selectedVendor.service_charges) >
                        0 && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            Additional Service Charges
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            &#8377;{" "}
                            {this.state.selectedVendor.service_charges.toLocaleString()}
                          </p>
                        </div>
                      )}

                    <div className={styles.itineraryLine}>
                      <p
                        className={css`
                          ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                        `}
                      >
                        Total Fare
                      </p>
                      <p
                        className={css`
                          ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                        `}
                      >
                        &#8377;{" "}
                        {Math.round(
                          this.state.selectedVendor.ride_amt +
                            this.state.selectedVendor.couponValue +
                            (this.state.selectedVendor.hasOwnProperty(
                              "driver_charges_per_day"
                            ) &&
                            this.state.selectedVendor.driver_charges_per_day !==
                              null
                              ? this.state.selectedVendor.driver_charges_per_day
                              : 0) +
                            (this.state.selectedVendor.hasOwnProperty(
                              "night_charges"
                            ) &&
                            this.state.selectedVendor.night_charges !== null
                              ? this.state.selectedVendor.night_charges
                              : 0) -
                            this.state.selectedVendor.security_deposit
                        ).toLocaleString()}
                      </p>
                    </div>
                    {this.state.selectedVendor.hasOwnProperty(
                      "security_deposit"
                    ) &&
                      this.state.selectedVendor.security_deposit !== null &&
                      parseInt(this.state.selectedVendor.security_deposit) >
                        0 && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            Security Deposit
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            &#8377;{" "}
                            {this.state.selectedVendor.security_deposit.toLocaleString()}
                          </p>
                        </div>
                      )}
                    <div className={styles.itineraryLine}>
                      <p
                        className={css`
                          ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                        `}
                      >
                        Payable Amount
                      </p>
                      <p
                        className={css`
                          ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                        `}
                      >
                        &#8377;{" "}
                        {Math.round(
                          this.state.selectedVendor.ride_amt +
                            this.state.selectedVendor.couponValue +
                            (this.state.selectedVendor.hasOwnProperty(
                              "driver_charges_per_day"
                            ) &&
                            this.state.selectedVendor.driver_charges_per_day !==
                              null
                              ? this.state.selectedVendor.driver_charges_per_day
                              : 0) +
                            (this.state.selectedVendor.hasOwnProperty(
                              "night_charges"
                            ) &&
                            this.state.selectedVendor.night_charges !== null
                              ? this.state.selectedVendor.night_charges
                              : 0)
                        ).toLocaleString()}
                      </p>
                    </div>

                    {this.state.selectedVendor.hasOwnProperty(
                      "extra_cost_per_km"
                    ) &&
                      this.state.selectedVendor.extra_cost_per_km !== null &&
                      parseInt(this.state.selectedVendor.extra_cost_per_km) >
                        0 && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            Extra Cost per Km
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            &#8377;{" "}
                            {this.state.selectedVendor.extra_cost_per_km.toLocaleString()}
                          </p>
                        </div>
                      )}

                    {this.state.selectedVendor.hasOwnProperty(
                      "extra_cost_per_hr"
                    ) &&
                      this.state.selectedVendor.extra_cost_per_hr !== null &&
                      parseInt(this.state.selectedVendor.extra_cost_per_hr) >
                        0 && (
                        <div className={styles.itineraryLine}>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            Extra Cost per Hr
                          </p>
                          <p
                            className={css`
                              ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                            `}
                          >
                            &#8377;{" "}
                            {this.state.selectedVendor.extra_cost_per_hr.toLocaleString()}
                          </p>
                        </div>
                      )}
                  </div>
                )}
              </AccordionItem>

              <AccordionItem title="Itinerary">
                <div className={styles.itineraryLine}>
                  <p
                    className={css`
                      ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                    `}
                  >
                    Pickup City
                  </p>
                  <p
                    className={css`
                      ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                    `}
                  >
                    {ride.pickup.city}
                  </p>
                </div>
                <div className={styles.itineraryLine}>
                  <p
                    className={css`
                      ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                    `}
                  >
                    Pickup Location
                  </p>
                  <p
                    className={css`
                      ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                    `}
                  >
                    {ride.pickup.loc}
                  </p>
                  {this.state.selectedVendor.account_name === "Driven" && (
                    <span>
                      {(this.props.ride.pickup.city === "Hyderabad" ||
                        this.props.ride.pickup.city === "Secunderabad") && (
                        <p
                          className={css`
                            ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                          `}
                        >
                          82, Rd Number 7, Women's Welfare Housing Society,
                          Jubilee Hills,Hyderabad, Telangana 500033, India
                        </p>
                      )}
                      {(this.props.ride.pickup.city === "Bangalore" ||
                        this.props.ride.pickup.city === "Bengaluru Rural" ||
                        this.props.ride.pickup.city === "Bengaluru Urban" ||
                        this.props.ride.pickup.city === "Bangalore Rural" ||
                        this.props.ride.pickup.city === "Bangalore Urban" ||
                        this.props.ride.pickup.city === "Bengaluru") && (
                        <p
                          className={css`
                            ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                          `}
                        >
                          621, 4th Cross Rd, near Sony signals, 6th Block,
                          Koramangala, Bengaluru, Karnataka 560095, India
                        </p>
                      )}
                      {this.props.ride.pickup.city === "Chennai" && (
                        <p
                          className={css`
                            ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                          `}
                        >
                          107/2A1, Poonamallee High Rd, Senneer Kuppam,
                          Goparasanallur,Chennai, Tamil Nadu 600056, India
                        </p>
                      )}
                      {(this.props.ride.pickup.city === "Vizag" ||
                        this.props.ride.pickup.city === "Visakhapatnam") && (
                        <p
                          className={css`
                            ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                          `}
                        >
                          The Park, Visakhapatnam, Beach Rd, near VUDA Park,
                          Jalari Peta, Visakhapatnam, Andhra Pradesh 530023
                        </p>
                      )}
                    </span>
                  )}
                </div>
                {this.props.ride.rentalType < 3 && (
                  <div className={styles.itineraryLine}>
                    <p
                      className={css`
                        ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                      `}
                    >
                      Destination City
                    </p>
                    <p
                      className={css`
                        ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                      `}
                    >
                      {ride.dest.city}
                    </p>
                  </div>
                )}
                {this.props.ride.rentalType === 4 && (
                  <div className={styles.itineraryLine}>
                    <p
                      className={css`
                        ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                      `}
                    >
                      Destination
                    </p>
                    <p
                      className={css`
                        ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                      `}
                    >
                      {ride.dest.loc}
                    </p>
                  </div>
                )}
                <div className={styles.itineraryLine}>
                  <p
                    className={css`
                      ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                    `}
                  >
                    Start Date &amp; Time
                  </p>
                  <p
                    className={css`
                      ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                    `}
                  >
                    {Moment(startDate, "DD-MM-YYYY").format("DD MMM, YYYY") +
                      " " +
                      startTime}
                  </p>
                </div>
                {this.props.ride.rentalType === 2 && (
                  <div className={styles.itineraryLine}>
                    <p
                      className={css`
                        ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                      `}
                    >
                      End Date &amp; Time
                    </p>
                    <p
                      className={css`
                        ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                      `}
                    >
                      {Moment(endDate, "DD-MM-YYYY").format("DD MMM, YYYY") +
                        " " +
                        endTime}
                    </p>
                  </div>
                )}
                {//Checks for Roundtrip
                !this.state.selectedVendor.measure_cd === "ONEWAY" &&
                  //Checks if Rental Type is Chauffer Driven or Self Drive
                  this.props.ride.rentalType < 3 && (
                    <div className={styles.itineraryLine}>
                      <p
                        className={css`
                          ${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}
                        `}
                      >
                        {this.state.selectedVendor.measure_cd === "ONEWAY"
                          ? ""
                          : "End Date & Time"}
                      </p>
                      <p
                        className={css`
                          ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                        `}
                      >
                        {this.state.selectedVendor.measure_cd === "ONEWAY"
                          ? ""
                          : Moment(endDate, "DD-MM-YYYY").format(
                              "DD MMM, YYYY"
                            ) +
                            " " +
                            endTime}
                      </p>
                    </div>
                  )}
              </AccordionItem>
              <AccordionItem title="Important Terms You Should Know">
                {this.state.selectedVendor.hasOwnProperty(
                  "terms_conditions"
                ) ? (
                  this.state.selectedVendor.terms_conditions.map((terms) => {
                    return (
                      <p
                        className={css`
                          ${tw`block w-full px-6 leading-loose text-grey-dark`}
                        `}
                      >
                        {terms}
                      </p>
                    );
                  })
                ) : (
                  <div
                    className={css`
                      ${tw`m-6`}
                    `}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={this.state.selectedVendor.terms_conditions_url}
                    >
                      {this.state.selectedVendor.terms_conditions_url}
                    </a>
                  </div>
                )}
              </AccordionItem>
              {this.state.vendors.length > 1 && (
                <AccordionItem title="More Choices" type={"vendors"}>
                  <div
                    className={
                      css`
                        ${tw`w-full py-8 px-6`}
                      ` + " row"
                    }
                  >
                    <div
                      className={css`
                        ${tw`w-full`}
                      `}
                    >
                      <p
                        className={css`
                          ${tw`w-full lg:w-2/3 block text-grey-dark opacity-60 font-sans text-sm lg:text-normal tracking-normal leading-normal`}
                        `}
                      >
                        Your selected choice of vehicle is also being provided
                        by the following vendors. The prices are different
                        because each of these companies have a different pricing
                        structures, offers. You can also choose to book from one
                        of these vendors if you'd like for whatever reason.
                      </p>
                    </div>
                    {this.props.vendors.category === "SELF DRIVE" && (
                      <div
                        className={css`
                          ${tw`mt-3 z-200 flex flex-row align-center bg-white justify-center px-2 cursor-pointer items-center`}
                        `}
                      >
                        <label htmlFor="material-switch">
                          {!this.state.isFuel && (
                            <img
                              alt="fuel"
                              className={css`
                                ${tw`h-8 mr-2 cursor-pointer`}
                              `}
                              src={require("../assets/img/fuel.png")}
                            />
                          )}
                          {this.state.isFuel && (
                            <img
                              alt="fuel_selected"
                              className={css`
                                ${tw`h-8 mr-2 cursor-pointer`}
                              `}
                              src={require("../assets/img/fuelSelected.svg")}
                            />
                          )}
                          <Switch
                            checked={this.state.isFuel}
                            onChange={() => {
                              this.setState({ isFuel: !this.state.isFuel });
                            }}
                            onColor="#D2B1EF"
                            onHandleColor="#740DCE"
                            handleDiameter={25}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                            activeBoxShadow="0 0 5px 2px rgba(0,0,0,.35)"
                            height={20}
                            width={40}
                            className="react-switch"
                            id="material-switch"
                          />
                        </label>
                      </div>
                    )}
                    {this.state.vendors.map((vendor) => {
                      // if(_.isEqual(vendor, this.state.selectedVendor)){
                      //     return
                      // }
                      //    else{
                      if (
                        !this.state.isFuel &&
                        vendor.measure_cd.includes("without fuel") &&
                        this.props.vendors.category === "SELF DRIVE"
                      ) {
                        return (
                          <Spring
                            from={{ backgroundColor: "white" }}
                            to={{
                              backgroundColor:
                                this.props.vendors.category === "SELF DRIVE" &&
                                this.state.selectedVendor.account_id ===
                                  vendor.account_id &&
                                this.state.selectedVendor.package_kms ===
                                  vendor.package_kms &&
                                this.state.selectedVendor.ride_amt ===
                                  vendor.ride_amt
                                  ? "rgb(227, 230, 232)"
                                  : this.props.vendors.category !==
                                      "SELF DRIVE" &&
                                    this.state.selectedVendor.account_id ===
                                      vendor.account_id
                                  ? "rgb(227, 230, 232)"
                                  : "white",
                            }}
                            config={{ tension: 100, friction: 10 }}
                          >
                            {(values) => (
                              <div
                                style={values}
                                onClick={() => {
                                  this.handleVendorPress(vendor);
                                }}
                                className={css`
                                  ${tw`lg:w-1/6 h-24 w-full text-left px-2 lg:mr-1 my-2 border-grey-light text-white border-solid hover:border-none hover:bg-white hover:shadow-md py-4 flex items-center justify-between rounded-lg text-grey-dark`};
                                  transition: all 0.2s ease-in-out;
                                `}
                              >
                                <div
                                  className={css`
                                    ${tw`w-auto`}
                                  `}
                                >
                                  {vendor.couponValue > 0 ? (
                                    <p
                                      className={css`
                                        ${tw`line-through opacity-60 mt-2`}
                                      `}
                                    >
                                      &#8377;{" "}
                                      {Math.round(
                                        vendor.ride_amt
                                      ).toLocaleString()}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  <p
                                    className={css`
                                      ${tw`font-medium text-lg`}
                                    `}
                                  >
                                    &#8377;{" "}
                                    {Math.round(
                                      vendor.ride_amt - vendor.couponValue
                                    ).toLocaleString()}
                                  </p>
                                  {vendor.hasOwnProperty("package_kms") && (
                                    <p
                                      className={css`
                                        ${tw`opacity-50 text-xs lg:text-normal uppercase`}
                                      `}
                                    >
                                      For{" "}
                                      {this.props.vendors.category ===
                                      "SELF DRIVE"
                                        ? typeof vendor.package_kms === "string"
                                          ? vendor.package_kms
                                          : vendor.package_kms.toLocaleString()(
                                              typeof vendor.package_kms ===
                                                "string" &&
                                                (vendor.package_kms.includes(
                                                  "kms"
                                                ) ||
                                                  vendor.package_kms.includes(
                                                    "Kms"
                                                  ))
                                                ? ""
                                                : " Kms"
                                            )
                                        : vendor.package_kms.toLocaleString() +
                                          "Kms"}
                                    </p>
                                  )}
                                </div>
                                <div
                                  className={css`
                                    ${tw`w-auto text-right`}
                                  `}
                                >
                                  <img
                                    alt="vendor"
                                    className={css`
                                      ${tw`w-12`}
                                    `}
                                    src={vendor.image_url}
                                  />
                                  <p
                                    className={css`
                                      ${tw`text-sm text-grey-darker`}
                                    `}
                                  >
                                    <img
                                      alt="rating"
                                      className={css`
                                        ${tw`h-2 inline-flex`}
                                      `}
                                      src={require("../components/SvgComponents/starLabelSvg.svg")}
                                    />
                                    {vendor.rating}
                                  </p>
                                </div>
                              </div>
                            )}
                          </Spring>
                        );
                      }
                      if (
                        this.state.isFuel &&
                        vendor.measure_cd.includes("with fuel") &&
                        this.props.vendors.category === "SELF DRIVE"
                      ) {
                        return (
                          <Spring
                            from={{ backgroundColor: "white" }}
                            to={{
                              backgroundColor:
                                this.props.vendors.category === "SELF DRIVE" &&
                                this.state.selectedVendor.account_id ===
                                  vendor.account_id &&
                                this.state.selectedVendor.package_kms ===
                                  vendor.package_kms &&
                                this.state.selectedVendor.ride_amt ===
                                  vendor.ride_amt
                                  ? "rgb(227, 230, 232)"
                                  : this.props.vendors.category !==
                                      "SELF DRIVE" &&
                                    this.state.selectedVendor.account_id ===
                                      vendor.account_id
                                  ? "rgb(227, 230, 232)"
                                  : "white",
                            }}
                            config={{ tension: 100, friction: 10 }}
                          >
                            {(values) => (
                              <div
                                style={values}
                                onClick={() => {
                                  this.handleVendorPress(vendor);
                                }}
                                className={css`
                                  ${tw`lg:w-1/6 h-24 w-full text-left px-2 lg:mr-1 my-2 border-grey-light text-white border-solid hover:border-none hover:bg-white hover:shadow-md py-4 flex items-center justify-between rounded-lg text-grey-dark`};
                                  transition: all 0.2s ease-in-out;
                                `}
                              >
                                <div
                                  className={css`
                                    ${tw`w-auto`}
                                  `}
                                >
                                  {vendor.couponValue > 0 ? (
                                    <p
                                      className={css`
                                        ${tw`line-through opacity-60 mt-2`}
                                      `}
                                    >
                                      &#8377;{" "}
                                      {Math.round(
                                        vendor.ride_amt
                                      ).toLocaleString()}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  <p
                                    className={css`
                                      ${tw`font-medium text-lg`}
                                    `}
                                  >
                                    &#8377;{" "}
                                    {Math.round(
                                      vendor.ride_amt - vendor.couponValue
                                    ).toLocaleString()}
                                  </p>
                                  {vendor.hasOwnProperty("package_kms") && (
                                    <p
                                      className={css`
                                        ${tw`opacity-50 text-xs lg:text-normal uppercase`}
                                      `}
                                    >
                                      For{" "}
                                      {this.props.vendors.category ===
                                      "SELF DRIVE"
                                        ? typeof vendor.package_kms === "string"
                                          ? vendor.package_kms
                                          : vendor.package_kms.toLocaleString()(
                                              typeof vendor.package_kms ===
                                                "string" &&
                                                (vendor.package_kms.includes(
                                                  "kms"
                                                ) ||
                                                  vendor.package_kms.includes(
                                                    "Kms"
                                                  ))
                                                ? ""
                                                : " Kms"
                                            )
                                        : vendor.package_kms.toLocaleString() +
                                          "Kms"}
                                    </p>
                                  )}
                                </div>
                                <div
                                  className={css`
                                    ${tw`w-auto text-right`}
                                  `}
                                >
                                  <img
                                    alt="vendor"
                                    className={css`
                                      ${tw`w-12`}
                                    `}
                                    src={vendor.image_url}
                                  />
                                  <p
                                    className={css`
                                      ${tw`text-sm text-grey-darker`}
                                    `}
                                  >
                                    <img
                                      alt="rating"
                                      className={css`
                                        ${tw`h-2 inline-flex`}
                                      `}
                                      src={require("../components/SvgComponents/starLabelSvg.svg")}
                                    />
                                    {vendor.rating}
                                  </p>
                                </div>
                              </div>
                            )}
                          </Spring>
                        );
                      }
                      if (this.props.vendors.category !== "SELF DRIVE") {
                        return (
                          <Spring
                            from={{ backgroundColor: "white" }}
                            to={{
                              backgroundColor:
                                this.props.vendors.category === "SELF DRIVE" &&
                                this.state.selectedVendor.account_id ===
                                  vendor.account_id &&
                                this.state.selectedVendor.package_kms ===
                                  vendor.package_kms &&
                                this.state.selectedVendor.ride_amt ===
                                  vendor.ride_amt
                                  ? "rgb(227, 230, 232)"
                                  : this.props.vendors.category !==
                                      "SELF DRIVE" &&
                                    this.state.selectedVendor.account_id ===
                                      vendor.account_id
                                  ? "rgb(227, 230, 232)"
                                  : "white",
                            }}
                            config={{ tension: 100, friction: 10 }}
                          >
                            {(values) => (
                              <div
                                style={values}
                                onClick={() => {
                                  this.handleVendorPress(vendor);
                                }}
                                className={css`
                                  ${tw`lg:w-1/6 h-24 w-full text-left px-2 lg:mr-1 my-2 border-grey-light text-white border-solid hover:border-none hover:bg-white hover:shadow-md py-4 flex items-center justify-between rounded-lg text-grey-dark`};
                                  transition: all 0.2s ease-in-out;
                                `}
                              >
                                <div
                                  className={css`
                                    ${tw`w-auto`}
                                  `}
                                >
                                  {vendor.couponValue > 0 ? (
                                    <p
                                      className={css`
                                        ${tw`line-through opacity-60 mt-2`}
                                      `}
                                    >
                                      &#8377;{" "}
                                      {Math.round(
                                        vendor.ride_amt
                                      ).toLocaleString()}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  <p
                                    className={css`
                                      ${tw`font-medium text-lg`}
                                    `}
                                  >
                                    &#8377;{" "}
                                    {Math.round(
                                      vendor.ride_amt - vendor.couponValue
                                    ).toLocaleString()}
                                  </p>
                                  {vendor.hasOwnProperty("package_kms") && (
                                    <p
                                      className={css`
                                        ${tw`opacity-50 text-xs lg:text-normal uppercase`}
                                      `}
                                    >
                                      For{" "}
                                      {this.props.vendors.category ===
                                      "SELF DRIVE"
                                        ? typeof vendor.package_kms === "string"
                                          ? vendor.package_kms
                                          : vendor.package_kms.toLocaleString()(
                                              typeof vendor.package_kms ===
                                                "string" &&
                                                (vendor.package_kms.includes(
                                                  "kms"
                                                ) ||
                                                  vendor.package_kms.includes(
                                                    "Kms"
                                                  ))
                                                ? ""
                                                : " Kms"
                                            )
                                        : vendor.package_kms.toLocaleString() +
                                          "Kms"}
                                    </p>
                                  )}
                                </div>
                                <div
                                  className={css`
                                    ${tw`w-auto text-right`}
                                  `}
                                >
                                  <img
                                    alt="vendor"
                                    className={css`
                                      ${tw`w-12`}
                                    `}
                                    src={vendor.image_url}
                                  />
                                  <p
                                    className={css`
                                      ${tw`text-sm text-grey-darker`}
                                    `}
                                  >
                                    <img
                                      alt="rating"
                                      className={css`
                                        ${tw`h-2 inline-flex`}
                                      `}
                                      src={require("../components/SvgComponents/starLabelSvg.svg")}
                                    />
                                    {vendor.rating}
                                  </p>
                                </div>
                              </div>
                            )}
                          </Spring>
                        );
                      }
                      //    }
                    })}
                  </div>
                </AccordionItem>
              )}
            </div>

            {/* Majore Content is here */}
          </div>

          {/* Slider Modal */}
          {this.state.showBookingForm && (
            <ProfileSlider
              submitBid={(
                payFull,
                redeemPoints,
                isAdvancePaid,
                myChoizeBlockedVehicle
              ) => {
                this.submitBid(
                  payFull,
                  redeemPoints,
                  isAdvancePaid,
                  myChoizeBlockedVehicle
                );
              }}
              showBookingForm={this.state.showBookingForm}
              pickAddress={this.state.pickAddress}
              dropAddress={this.state.dropAddress}
              isSubmitting={this.state.isSubmitting}
              isAdvancePaid={this.state.isAdvancePaid}
              locationId={this.state.zoomLocationId}
              // openRazorpay = {(redeemPoints) => {this.openRazorpay(redeemPoints)}}
            />
          )}
          {/* End of Slider Modal */}

          {/* --------------OVERLAY DONT MOVE ! ---------------
           *This is an overlay to be activated whenever a popup is activated, it's the black transparent background layer thingy,
           * Don't change it's position as it might affect the stacking order (Zindex order)
           */}
          <BlackOverlay
            active={this.state.showOverlay}
            onClick={() =>
              this.setState({
                showOverlay: false,
                showBookingForm: false,
                idType: "licenseNo",
              })
            }
          />
          {/* OVERLAY DONT MOVE ! ---------------
           * Overlay Ends here
           */}

          {this.state.myChoizeSlider && (
            <SliderModal active={this.state.myChoizeSlider}>
              {!this.state.fetchingMyChoizeLocAndActivity && (
                <div
                  className={css`
                    ${tw`relative flex flex-wrap items-center lg:px-12 px-6 py-4 pb-24 max-h-full w-full overflow-y-scroll`};
                    -webkit-overflow-scrolling: touch;
                  `}
                >
                  {/* <div onClick={()=>{ navigate(`/carsList`)}} className={css`${tw`px-4 py-2 mt-4 mb-4 text-xl cursor-pointer outline-none shadow-none appearance-none bg-grey-light rounded-lg hover:bg-white hover:shadow-lg border-none`}`}>Back</div> */}
                  <p
                    className={css`
                      ${tw`lg:text-xl text-md font-bold text-grey-dark py-4`}
                    `}
                  >
                    Select Vehicle Pickup &amp; Dropoff address
                  </p>
                  {/* <div className={css`${tw`px-4 py-2 bg-gr`}`}>Back</div> */}
                  <div
                    className={css`
                      ${tw`w-full lg:flex lg:flex-row justify-between items-start`}
                    `}
                  >
                    <div
                      className={css`
                        ${tw`w-full lg:flex lg:flex-col justify-start text-left`}
                      `}
                    >
                      <p
                        className={css`
                          ${tw`text-left text-lg font-semibold`}
                        `}
                      >
                        Pickup
                      </p>
                      {this.state.locationList && (
                        <DropDown
                          options={
                            this.state.locationList.BranchesPickupLocationList
                          }
                          value={this.state.pickLabel}
                          onChange={(event) => {
                            console.log(event.value);
                            let data = event.value;
                            this.setState({
                              pickLocation: data.LocationKey,
                              pickLabel: data.label,
                              dropLocation: data.LocationKey,
                              dropLabel: data.label,
                            });
                            data.HubAddress.includes("AT MY ADDRESS")
                              ? this.setState({
                                  disablePick: false,
                                  pickAddress: "",
                                  myPickAddressMsg:
                                    "(Delivery charges might be applicable)",
                                  disableDrop: false,
                                  dropAddress: "",
                                  myDropAddressMsg:
                                    "(Delivery charges might be applicable)",
                                })
                              : this.setState({
                                  disablePick: true,
                                  pickAddress: data.HubAddress,
                                  myPickAddressMsg: "",
                                  disableDrop: true,
                                  dropAddress: data.HubAddress,
                                  myDropAddressMsg: "",
                                });
                          }}
                          placeholder="Pickup Location | Timings | Working Days | Charges"
                          className={css`
                            ${tw`w-full mt-4 font-medium text-xs lg:text-md`};
                          `}
                        />
                      )}

                      {!this.state.disablePick && (
                        <input
                          className={css`
                            ${tw`w-full bg-grey-light px-2 py-2 font-brand text-xl outline-none shadow-none appearance-none rounded-lg border-none mt-2`}
                          `}
                          value={this.state.pickAddress}
                          onChange={(event) => {
                            this.setState({
                              pickAddress: event.target.value,
                              dropAddress: event.target.value,
                            });
                            console.log(event);
                          }}
                        />
                      )}
                      <p
                        className={css`
                          ${tw`text-red font-hairline text-xs`}
                        `}
                      >
                        {this.state.myPickAddressMsg}
                      </p>
                    </div>
                    <div
                      className={css`
                        ${tw`w-full lg:flex mt-4 ml-0 lg:ml-8 lg:mt-0 lg:flex-col justify-start text-left`}
                      `}
                    >
                      <p
                        className={css`
                          ${tw`text-left text-lg font-semibold`}
                        `}
                      >
                        Dropoff
                      </p>
                      {this.state.locationList && (
                        <DropDown
                          options={
                            this.state.locationList.BranchesDropupLocationList
                          }
                          value={this.state.dropLabel}
                          onChange={(event) => {
                            console.log(event.value);
                            let data = event.value;
                            this.setState({
                              dropLocation: data.LocationKey,
                              dropLabel: data.label,
                            });
                            data.HubAddress.includes("AT MY ADDRESS")
                              ? this.setState({
                                  disableDrop: false,
                                  dropAddress: "",
                                  myDropAddressMsg:
                                    "(Delivery charges might be applicable)",
                                })
                              : this.setState({
                                  disableDrop: true,
                                  dropAddress: data.HubAddress,
                                  myDropAddressMsg: "",
                                });
                          }}
                          placeholder="Dropoff Location | Timings | Working Days | Charges"
                          className={css`
                            ${tw`w-full mt-4 font-medium text-xs lg:text-md`};
                          `}
                        />
                      )}
                      {!this.state.disableDrop && (
                        <input
                          className={css`
                            ${tw`w-full bg-grey-light px-2 py-2 font-brand text-xl outline-none shadow-none appearance-none rounded-lg border-none mt-2`}
                          `}
                          value={this.state.dropAddress}
                          onChange={(event) => {
                            this.setState({ dropAddress: event.target.value });
                            console.log(event);
                          }}
                        />
                      )}
                      {/* <textarea 
                className={css`${tw`w-full flex px-2 py-2 font-brand font-medium text-xs lg:text-md outline-none shadow-none mt-4`}; border-color:#ccc; `} 
                disabled={this.state.disableDrop} 
                value={this.state.dropAddress} 
                onChange={(event) => {this.setState({ dropAddress: event.target.value})}}
                maxlength='200' rows='6' /> */}
                      <p
                        className={css`
                          ${tw`text-red font-hairline text-xs`}
                        `}
                      >
                        {this.state.myDropAddressMsg}
                      </p>
                    </div>
                  </div>
                  <div
                    className={css`
                      ${tw`w-full lg:flex lg:flex-row justify-start items-center`}
                    `}
                  >
                    <div
                      className={css`
                        ${tw`w-full lg:flex lg:flex-col justify-start text-left`}
                      `}
                    >
                      <p
                        className={css`
                          ${tw`text-left text-lg mt-4 font-semibold`}
                        `}
                      >
                        Add Accessories
                      </p>
                      <div
                        className={css`
                          ${tw`lg:flex w-full`}
                        `}
                      >
                        {this.state.additionalActivityList.map((list, i) => (
                          <PaymentRadio
                            onClick={() => {
                              let array = this.state.activityKeyList;
                              this.state.activityKeyList[i] !==
                                this.state.additionalActivityList[i]
                                  .ActivityKey ||
                              this.state.activityKeyList[i] === "undefined"
                                ? (array[i] = list.ActivityKey)
                                : (array[i] = 0);
                              this.setState({ activityKeyList: array });
                            }}
                            active={
                              this.state.activityKeyList.length !== 0 &&
                              this.state.activityKeyList[i] ===
                                this.state.additionalActivityList[i].ActivityKey
                            }
                          >
                            <div
                              className={css`
                                ${tw`inline-flex self-center ml-4`}
                              `}
                            >
                              <p
                                className={css`
                                  ${tw`font-medium text-md lg:text-lg`}
                                `}
                              >
                                {list.ActivityDescription}
                              </p>
                            </div>
                          </PaymentRadio>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div
                    className={css`
                      ${tw`w-full my-4 flex lg:justify-end items-center`}
                    `}
                  >
                    <button
                      onClick={() => {
                        navigate(`/carsList`);
                      }}
                      className={css`
                        ${tw`w-full lg:w-1/4 px-4 py-4 mt-4 text-xl cursor-pointer outline-none shadow-none appearance-none bg-transparent hover:bg-grey-light rounded-md lg:mr-2 border-none`}
                      `}
                    >
                      Back
                    </button>
                    <button
                      onClick={this.handleMyChoizePricing}
                      className={css`
                        ${tw`w-full lg:w-1/4 px-4 py-4 mt-4 text-xl cursor-pointer outline-none shadow-none appearance-none bg-grey-light rounded-md hover:bg-white hover:shadow-lg border-none`}
                      `}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              )}
              {this.state.fetchingMyChoizeLocAndActivity && (
                <div
                  className={css`
                    ${tw`relative flex flex-wrap items-center lg:px-12 px-6 py-4 pb-24 max-h-full w-full`}
                  `}
                >
                  {/* <div onClick={()=>{ navigate(`/carsList`)}} className={css`${tw`px-4 py-2 mt-4 mb-4 text-xl cursor-pointer outline-none shadow-none appearance-none bg-grey-light rounded-lg hover:bg-white hover:shadow-lg border-none`}`}>Back</div> */}
                  <div
                    className={css`${tw`w-full flex justify-center align-middle`}; 
                                    }`}
                  >
                    <PulseLoader pColor="#7e00e1" />
                  </div>
                </div>
              )}
            </SliderModal>
          )}

          {this.state.showZoomSlider && (
            <ZoomLocationSlider
              active={this.state.showZoomSlider}
              locations={this.state.zoomLocations}
              handleZoomLocationSelect={this.handleZoomLocation}
            />
          )}

          <BlackOverlay
            active={
              this.state.showMyChoizeOverlay || this.state.showZoomOverlay
            }
            onClick={() =>
              this.setState({
                showMyChoizeOverlay: true,
                myChoizeSlider: true,
                idType: "licenseNo",
                showZoomOverlay: true,
                showZoomSlider: true,
              })
            }
          />

          {this.state.showLocationSlider && (
            <SliderModal active={this.state.showLocationSlider}>
              <div
                className={css`
                  ${tw`relative flex flex-wrap items-center lg:px-12 px-6 py-4 pb-24 max-h-full w-full overflow-y-scroll`};
                  -webkit-overflow-scrolling: touch;
                `}
              >
                <p
                  className={css`
                    ${tw`lg:text-xl text-md font-bold text-grey-dark py-4`}
                  `}
                >
                  Available Vehicle Pickup &amp; Dropoff address
                </p>

                {(this.props.ride.pickup.city === "Hyderabad" ||
                  this.props.ride.pickup.city === "Secunderabad") && (
                  <p
                    className={css`
                      ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                    `}
                  >
                    82, Rd Number 7, Women's Welfare Housing Society, Jubilee
                    Hills,Hyderabad, Telangana 500033, India
                  </p>
                )}
                {(this.props.ride.pickup.city === "Bangalore" ||
                  this.props.ride.pickup.city === "Bengaluru Rural" ||
                  this.props.ride.pickup.city === "Bengaluru Urban" ||
                  this.props.ride.pickup.city === "Bangalore Rural" ||
                  this.props.ride.pickup.city === "Bangalore Urban" ||
                  this.props.ride.pickup.city === "Bengaluru") && (
                  <p
                    className={css`
                      ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                    `}
                  >
                    621, 4th Cross Rd, near Sony signals, 6th Block,
                    Koramangala, Bengaluru, Karnataka 560095, India
                  </p>
                )}
                {this.props.ride.pickup.city === "Chennai" && (
                  <p
                    className={css`
                      ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                    `}
                  >
                    107/2A1, Poonamallee High Rd, Senneer Kuppam,
                    Goparasanallur,Chennai, Tamil Nadu 600056, India
                  </p>
                )}
                {(this.props.ride.pickup.city === "Vizag" ||
                  this.props.ride.pickup.city === "Visakhapatnam") && (
                  <p
                    className={css`
                      ${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}
                    `}
                  >
                    The Park, Visakhapatnam, Beach Rd, near VUDA Park, Jalari
                    Peta, Visakhapatnam, Andhra Pradesh 530023
                  </p>
                )}
              </div>
              <div
                className={css`
                  ${tw`w-full my-4 flex lg:justify-end items-center`}
                `}
              >
                <button
                  onClick={() => this.setState({ showLocationSlider: false })}
                  className={css`
                    ${tw`w-full lg:w-1/4 px-4 py-4 mt-4 mr-4 text-xl cursor-pointer outline-none shadow-none appearance-none bg-grey-light rounded-md hover:bg-white hover:shadow-lg border-none`}
                  `}
                >
                  Proceed
                </button>
              </div>
            </SliderModal>
          )}

          <BlackOverlay
            active={this.state.showLocationSlider}
            onClick={() => this.setState({ showLocationSlider: false })}
          />

          {this.state.showTerms && (
            <SliderModal active={this.state.showTerms}>
              <div
                className={css`
                  ${tw`w-full -mx-2 px-4 py-12 lg:px-12 pb-24 h-full overflow-y-scroll relative z-500`};
                  z-index: 1000;
                `}
              >
                <div
                  className={css`
                    ${tw`w-full flex px-4 items-center`}
                  `}
                >
                  <div
                    className={css`
                      ${tw`w-auto`}
                    `}
                  >
                    <p
                      className={css`
                        ${tw`lg:text-xl text-md font-bold text-grey-dark`}
                      `}
                    >
                      Terms and Conditions
                    </p>
                  </div>
                </div>
                <div
                  className={css`
                    ${tw`w-full my-4`}
                  `}
                >
                  {this.state.selectedVendor.hasOwnProperty(
                    "terms_conditions"
                  ) ? (
                    this.state.selectedVendor.terms_conditions.map((terms) => {
                      return (
                        <span
                          className={css`
                            ${tw`flex`}
                          `}
                        >
                          <p
                            className={css`
                              ${tw`flex`}
                            `}
                          >
                            &#62;{" "}
                          </p>
                          <p
                            className={css`
                              ${tw`flex w-full px-6 leading-loose text-grey-dark`}
                            `}
                          >
                            {terms}
                          </p>
                        </span>
                      );
                    })
                  ) : (
                    <div
                      className={css`
                        ${tw`m-6`}
                      `}
                    >
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={this.state.selectedVendor.terms_conditions_url}
                      >
                        {this.state.selectedVendor.terms_conditions_url}
                      </a>
                    </div>
                  )}
                </div>
                <div
                  className={css`
                    ${tw`w-full my-4 flex lg:justify-end items-center`}
                  `}
                >
                  <button
                    onClick={() => {
                      this.handleTerms();
                    }}
                    className={css`
                      ${tw`w-full lg:w-1/4 px-4 py-4 mt-4 text-xl cursor-pointer appearance-none bg-grey-light rounded-md outline-none shadow-none hover:bg-white hover:shadow-lg border-none`}
                    `}
                  >
                    Agree
                  </button>
                </div>
              </div>
            </SliderModal>
          )}

          <BlackOverlay
            active={this.state.showTermsOverlay}
            onClick={() =>
              this.setState({
                showTerms: false,
                showTermsOverlay: false,
                idType: "licenseNo",
              })
            }
          />
        </div>

        {this.state.showProceed && (
          <div
            onClick={() => {
              this.handleTerms();
            }}
            className={styles.choiceFilterButton}
          >
            {/* <p> Proceed with {this.state.selectedVendor.account_name} @ &#8377;{Math.round(this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue).toLocaleString()}</p> */}
            <p>Proceed</p>
          </div>
        )}

        {this.state.menuOpen && (
          <Menu
            onClosePress={() => {
              this.setState({ menuOpen: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  vendors: state.cars.vendors,
  ride: state.ride,
  isUserLoggedIn: state.user.isLoggedIn,
  selectedCar: state.order.selectedCar,
  userName: state.user.name,
  userPhone: state.user.phone,
  userEmail: state.user.email,
  user: state.user,
  selectedVendor: state.order.selectedVendor,
  myChoize: state.myChoize,
});

export default connect(mapStateToProps)(reviewPage);

// header for city dropdown

// ( (this.props.ride.rentalType !== 1 && this.props.selectedVendor.account_name !== 'MyChoize') || (this.props.ride.rentalType === 2 && this.props.selectedVendor.account_name !== 'MyChoize') ? 'Your location please' : 'Any special needs to pass on? (Optional)')

// pickup/drop logic for city drop

// ((this.props.ride.rentalType !== 1 && this.props.selectedVendor.account_name !== 'MyChoize') || (this.props.ride.rentalType === 2 && this.props.selectedVendor.account_name !== 'MyChoize') ?
// <div className={css`${tw`w-full lg:flex lg:flex-row justify-between items-start`}`}>
// <div className={css`${tw`w-full lg:flex lg:flex-col justify-start text-left`}`}>
// <PlacesAutocomplete
// value={this.state.pickAddress}
// onChange={(event)=>{this.setState({ pickAddress: event}); console.log(event)}}
// onSelect={(event)=>{this.setState({ pickAddress: event});this.handleSelect(event,true);console.log(event)}}
// searchOptions={options}
// shouldFetchSuggestions={this.state.pickAddress.length > 1}
// // ref="myInput">
// inputProps={{
// ref: input => {
// console.log("REF INPUT SET");
// this.searchInput = input;
// }
// }}>
// {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
// <>
// {/* <input
// id="inputRef"
// {...getInputProps({
// autoFocus: true,
// placeholder: 'Search Places...',
// className: css`${tw`w-full px-4 text-xl lg:text-2xl font-bold py-4 border-none appearance-none bg-grey-light outline-none`}`,
// })}
// /> */}
// <textarea
// id="inputRef"
// {...getInputProps({
// // autoFocus: true,
// placeholder: 'Enter pickup point',
// // className: css`${tw`w-full px-4 text-xl lg:text-2xl font-bold py-4 border-none appearance-none bg-grey-light outline-none`}`,
// })}
// className={css`${tw`w-full flex px-2 py-2 font-brand font-medium text-xs lg:text-md outline-none shadow-none mt-4`}; border-color:#ccc; `}
// // disabled={this.state.disablePick}
// // value={this.state.pickAddress}
// // onChange={(event) => {this.setState({ pickAddress: event.target.value})}}
// maxlength='200' rows='2' />
// <div className={css`${tw`w-full lg:w-1/2 px-2 py-0 absolute z-50 lg:mt-16`}; max-height:12rem; overflow-y:scroll; -webkit-overflow-scrolling: touch; background-color: #ffffff;`}>
// {loading && <div>Loading...</div>}
// {suggestions.map(suggestion => {
// const className = suggestion.active
//     ? css`${tw`my-4 lg:text-xl lg:py-1 text-grey-dark font-poppins font-medium tracking-tight`}`
//     : css`${tw`my-4 lg:text-xl lg:py-1 bg-grey-light text-grey-darker font-poppins font-medium tracking-tight`}`;
// // inline style for demonstration purpose
// const style = suggestion.active
//     ? { backgroundColor: '#F8F8F8', cursor: 'pointer' }
//     : { backgroundColor: '#ffffff', cursor: 'pointer' };
// return (
//     <div
//         {...getSuggestionItemProps(suggestion, {
//             className,
//             style,
//         })}
//     >
//         <span>{suggestion.description}</span>
//     </div>
// );
// })}
// </div>
// </>
// )}
// </PlacesAutocomplete>
// </div>
// {this.props.ride.rentalType === 3 && <div className={css`${tw`w-full lg:flex mt-4 ml-0 lg:ml-8 lg:mt-0 lg:flex-col justify-start text-left`}`}>
// <PlacesAutocomplete
// value={this.state.dropAddress}
// onChange={(event)=>{this.setState({ dropAddress: event}); console.log(event)}}
// onSelect={(event)=>{this.setState({ dropAddress: event});this.handleSelect(event,false);console.log(event)}}
// searchOptions={options}
// shouldFetchSuggestions={this.state.dropAddress.length > 1}
// // ref="myInput">
// inputProps={{
// ref: input => {
// console.log("REF INPUT SET");
// this.searchInput = input;
// }
// }}>
// {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
// <>
// {/* <input
// id="inputRef"
// {...getInputProps({
// autoFocus: true,
// placeholder: 'Search Places...',
// className: css`${tw`w-full px-4 text-xl lg:text-2xl font-bold py-4 border-none appearance-none bg-grey-light outline-none`}`,
// })}
// /> */}
// <textarea
// id="inputRef"
// {...getInputProps({
// // autoFocus: true,
// placeholder: 'Enter dropoff point',
// // className: css`${tw`w-full px-4 text-xl lg:text-2xl font-bold py-4 border-none appearance-none bg-grey-light outline-none`}`,
// })}
// className={css`${tw`w-full flex px-2 py-2 font-brand font-medium text-xs lg:text-md outline-none shadow-none mt-4`}; border-color:#ccc; `}
// // disabled={this.state.disableDrop}
// // value={this.state.pickAddress}
// // onChange={(event) => {this.setState({ pickAddress: event.target.value})}}
// maxlength='200' rows='2' />
// <div className={css`${tw`w-full lg:w-1/2 px-2 py-0 absolute z-50 lg:mt-16`}; max-height:12rem; overflow-y:scroll; -webkit-overflow-scrolling: touch; background-color: #ffffff;`}>
// {loading && <div>Loading...</div>}
// {suggestions.map(suggestion => {
// const className = suggestion.active
//     ? css`${tw`my-4 lg:text-xl lg:py-1 text-grey-dark font-poppins font-medium tracking-tight`}`
//     : css`${tw`my-4 lg:text-xl lg:py-1 bg-grey-light text-grey-darker font-poppins font-medium tracking-tight`}`;
// // inline style for demonstration purpose
// const style = suggestion.active
//     ? { backgroundColor: '#F8F8F8', cursor: 'pointer' }
//     : { backgroundColor: '#ffffff', cursor: 'pointer' };
// return (
//     <div
//         {...getSuggestionItemProps(suggestion, {
//             className,
//             style,
//         })}
//     >
//         <span>{suggestion.description}</span>
//     </div>
// );
// })}
// </div>
// </>
// )}
// </PlacesAutocomplete>
// </div>}
// </div>
